import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const Footer = (props) => {
    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        {/* <Col md={6}>
                         Powered by  <a className='gtrendz-text' href="http://globaltrendz.com/" target="_blank">Global Trendz </a>
                        </Col> */}
                        <Col md={12}>
                            <div className="text-sm-right d-none d-sm-block">
                                {new Date().getFullYear()} © Global Trendz.  All rights reserved

                            </div>    </Col>

                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
