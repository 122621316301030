import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import PhotoUploader from "../../components/PhotoUploader";
import GETApiCall from "../../GETAPI";
import Notiflix from "notiflix";
import POSTApiCall from "../../Api";
import { Target } from "react-feather";

class AddEmployee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Name: '',
      Department: '',

    }
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: '#fa6e03'

    });
    // Notiflix.Loading.Standard();

  }

  onchangeName(text) {
    this.setState({
      Name: text.target.value
    })
  }

  onchangeDepartment(text) {
    this.setState({
      Department: text.target.value
    })
  }

  saveName() {
    if (this.state.Name != '') {
      if (this.state.Department != '') {
        console.log('true')
      }
      else {
        Notiflix.Notify.Failure('Please enter Department');
      }
    }
    else {
      Notiflix.Notify.Failure('Please enter name');
    }
  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Global Trendz "}
              breadcrumbItem={"Add Employee"}
            />

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="gtrendz-bg-soft-pink">
                    <Row>
                      <Col xs="9">
                        <div className="gtrendz-text  p-3">
                          <h5 className="gtrendz-text">
                            Add Employee Master
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>


                  <CardBody className="pt-0" style={{ height: "100%" }}>
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Employee Name</label>
                                  <input className="form-control"
                                    value={this.state.Name}
                                    onChange={this.onchangeName.bind(this)}>
                                  </input>
                                </div>
                                <div className="col-md-6">
                                  <label>Department</label>
                                  <input className="form-control"
                                    value={this.state.Department}
                                    onChange={this.onchangeDepartment.bind(this)}>
                                  </input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>


                </Card>


                <Card>
                  <CardBody>





                    <div>
                      <Button
                        style={{ float: "right" }}
                        className=" btn gtrendz-btn "
                        onClick={this.saveName.bind(this)}
                      >
                        Submit{" "}

                      </Button>
                    </div>


                  </CardBody>
                </Card>


              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddEmployee;
