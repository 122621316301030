import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from "moment";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BiUserPlus } from "react-icons/bi";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Dropdown from "react-bootstrap/Dropdown";
// import { confirmAlert } from "react-confirm-alert";
import Filtericon from "../../assets/images/dashboardimages/filter.png";
import Searchicon from "../../assets/images/dashboardimages/search.png";
import Downloadicon from "../../assets/images/dashboardimages/down-arrow.png";
import Calendericon from "../../assets/images/dashboardimages/calendar.png";
import Refreshicon from "../../assets/images/dashboardimages/refresh.png";
// import Viewicon from '../../assets/images/dashboardimages/file.png'
// import Carticon from '../../assets/images/dashboardimages/shopping-cart.png'
// import Eyeicon from '../../assets/images/dashboardimages/eye.png'
// ../../assets/images/dashboardimages/eye.png'
import QRCode from "qrcode";

import { ImCross } from "react-icons/im";

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();

    var curryear = new Date().getFullYear();
    // console.log(curryear)

    this.state = {
      ShowModal: false,
      ShowUpdateOrderModal: false,
      UserData: [],
      userid: null,
      Id: "",
      searchInput: "",
      FromDate: null,
      ToDate: null,
      VisitorData: [],
      ViewUpdateData: [],
      ItemList: [],
      CustomerData: [],
      SearchField: null,
      fileData: [],
      ShowItemModal: false,
      Item_Ids: [],
      selectedorderid: null,
      UserDepartment: JSON.parse(localStorage.getItem("LoginDetail"))[0]
        .fld_department,
      qrdata: "",
      CountryData: [],
      EmailRegex:
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      NumRegex: /^[0-9]*$/,
      firstname: "",
      mobile1: "",
      emailid: "",
      designation: "",
      company: "",
      source: "",
      year: curryear,
      yearfilter: [],
      theDates: [],
      selectedYear: null,
    };
  }

  //   onOpenModal = () => {
  //     this.setState({ open: true });
  //   };

  //   onCloseModal = () => {
  //     this.setState({ open: false });
  //   };
  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    Notiflix.Loading.Dots("Please wait...");

    Notiflix.Notify.Init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
    });

    for (let year = new Date().getFullYear(); year !== null; ) {
      for (let i = 0; i < year; i++, year = year > 2020 ? year - 1 : null) {
        this.state.theDates.push(year);
      }
    }
    this.getVisitors();
  }

  getVisitors() {
    PostApiCall.postRequest(
      {
        WhereClause: ``,
      },
      "Get_Visitor"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data);
          this.setState({ VisitorData: obj.data });

          this.setState({ yearfilter: obj.data[0].FLD_CREATED_ON });

          // console.log(this.state.yearfilter)

          Notiflix.Loading.Remove();
          // {console.log(this.state.yearfilter.date.getFullYear())}
        }
      })
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.year == this.state.year) {
      // Filter data based on the selected year
      if (this.state.year === "") {
        this.setState({ VisitorData: this.state.data }); // If no year is selected, show all data
      } else {
        // const filtered = this.state.data.filter(item => item.year === parseInt(this.state.year));
        // this.setState({ VisitorData: filtered });
      }
    }
  }

  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");

    PostApiCall.postRequest(
      {
        RecordCount: "*",
        // WhereClause: `where convert(datetime,fld_orde,103) BETWEEN cast('${moment(
        WhereClause: `where convert(datetime,fld_created_on,103) BETWEEN cast('${moment(
          this.state.FromDate == null ? null : this.state.FromDate
        ).format("MM-DD-YYYY")}' as date) AND cast('${moment(
          this.state.ToDate == null ? null : this.state.ToDate
        ).format("MM-DD-YYYY")}' as date)
    `,
      },
      "Get_Visitor"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //console.log(obj.data)
          this.setState({ VisitorData: obj.data });
          Notiflix.Loading.Remove();
        }
      })
    );
  };

  onPostSearch() {
    Notiflix.Loading.Dots("Please wait...");

    PostApiCall.postRequest(
      {
        RecordCount: "*",
        WhereClause: `where upper(SearchFiled) LIKE isnull('%${
          this.state.SearchField == null ? "" : this.state.SearchField
        }%',upper(SearchFiled))
				`,
      },
      "Get_Visitor"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          //console.log(obj.data)
          this.setState({
            VisitorData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  DownloadCSV = () => {
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        // WhereClause: `where fld_source='IFEX'`,
        WhereClause: ``,
        RecordCount: `*`,
      },
      "Get_Visitor"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length != 0) {
            // this.props.setstate()
            this.setState(
              {
                fileData: obj.data,
              },
              () => {
                setTimeout(() => {
                  this.csvLink.current.link.click();
                });
              }
            );
          }
          Notiflix.Loading.Remove();
        }
      })
    );
  };

  updateOrder() {
    PostApiCall.postRequest(
      {
        orderid: this.state.selectedorderid,
        itemdetails: JSON.stringify(
          this.state.Item_Ids.map((val) => ({
            ItemId: val,
            Status: "Dispatched",
          }))
        ),
      },
      "updateorder"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data)

          this.setState({
            ShowModal: false,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  visitoradmin = async () => {
    // console.log(this.state.userid)

    Notiflix.Loading.Dots("Please wait...");

    // console.log(this.state.userid);

    PostApiCall.postRequest(
      {
        userid: this.state.userid,
        firstname: this.state.firstname,
        mobile1: this.state.mobile1,
        emailid: this.state.emailid,
        designation: this.state.designation,
        company: this.state.company,
        qrdata: this.state.qrdata,
        source: this.state.source,
      },
      "update_visitor_admin"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Data Saved Successully");

          QRCode.toDataURL(`${obj.data[0].visitornumber}`)
            .then((url) => {
              PostApiCall.postRequest(
                {
                  id: obj.data[0].visitornumber,
                  email: this.state.emailid,
                  name: this.state.firstname,
                  qrdata: url,
                },
                "send_mail_visitor_admin"
              ).then((resultmail) =>
                resultmail.json().then((obj1) => {
                  // console.log(resultmail);
                  if (resultmail.status == 200 || resultmail.status == 201) {
                    // console.log(obj1);
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("Mail Send Succesfully.");
                    window.location.reload();
                  } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj1.data);
                  }
                })
              );
            })
            .catch((err) => {
              console.error(err);
            });

          //   window.location.Reload();
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  handleYearChange = (e) => {
    this.setState({ year: e.target.value });
  };

  sendmailer = async (id, email, name, visitorid) => {
    Notiflix.Loading.Dots()
    QRCode.toDataURL(visitorid).then((url) => {
      PostApiCall.postRequest(
        {
          id: id,
          email: email,
          name: name,
          qrdata: url,
        },
        "Send_Ticket_Visitor_Mailer"
      ).then((results) =>
        results.json().then((obj) => {
          if (results.status == 200 || results.status == 201) {
            Notiflix.Loading.Remove();
            Notiflix.Notify.Success(
              "Email send successfully"
            );
            this.getVisitors();
          } else {
            Notiflix.Loading.Remove();
            Notiflix.Notify.Failure(obj.data);
          }
        })
      );
    });
  };

  render() {
    // console.log(this.state.Item_Ids.toString())
    const { open } = this.state;

    const data = {
      columns: [
        {
          label: "S.No.",
          field: "sn",
          // sort: "asc",
          width: 150,
        },
        {
          label: "User ID",
          field: "userid",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "fullname",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Email ID",
          field: "email",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Mobile Number",
          field: "number",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Created On",
          field: "create",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Source",
          field: "source",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.VisitorData.filter((data) => {
        // console.log(
        //   new Date(data.FLD_CREATED_ON).getFullYear(),
        //   this.state.selectedYear
        // );
        if (
          this.state.searchInput == "" &&
          new Date(data.FLD_CREATED_ON).getFullYear() == this.state.selectedYear
        ) {
          return data;
        }
        if (this.state.searchInput == "" && this.state.selectedYear == null) {
          return data;
        }

        if (
          this.state.searchInput !== "" &&
          String(data.FLD_FIRST_NAME).toLowerCase().includes(
            this.state.searchInput.toLowerCase()
          )
        ) {
          return data;
        }

        if (
          this.state.searchInput !== "" &&
          // (data.FLD_USER_ID.includes(this.state.searchInput) ||
          //   data
          //     .fld_sourcetoLowerCase()
          //     .includes(this.state.searchInput))
          String(data.FLD_USER_ID).includes(
            this.state.searchInput.toLowerCase()          
        ) 
        ){
          return data;
        }

        if (
          this.state.searchInput !== "" &&
          String(data.FLD_MOBILE1).includes(
            this.state.searchInput.toLowerCase()          
        ) 
        ){
          return data;
        }
      }).map((data, i) => {
        return {
          sn: i + 1,
          // orderid: data.fld_order_id,
          userid: data.FLD_USER_ID,
          fullname: data.FLD_FIRST_NAME,
          email: data.FLD_EMAIL_ID,
          number: data.FLD_MOBILE1,
          create: moment(data.FLD_CREATED_ON).format("DD/MM/YYYY"),
          source: data.fld_source,
          action: (
            <div className="d-flex">
              <span className="" style={{ cursor: "pointer" }}>
                <a
                  href={`/view-visitor/${data.FLD_USER_ID}`}
                  className="btn align-items-center btn Bechofy-btn mr-3"
                >
                  View{" "}
                </a>
              </span>
              <span className="" style={{ cursor: "pointer" }}>
                <a
                  className="btn align-items-center btn Bechofy-btn mr-3 "
                  onClick={() => {
                    this.sendmailer(
                      data.FLD_USER_ID,
                      data.FLD_EMAIL_ID,
                      data.FLD_FIRST_NAME,
                      data.FLD_VISITOR_NUMBER
                    );
                  }}
                  style={{
                    display: data.FLD_SEND_MAIL == null ? "flex" : "none",
                  }}
                >
                  Send Mail{" "}
                </a>
              </span>
            </div>
          ),
        };
      }),
    };

    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem={"Dashboard"} urlPath={"/dashboard"} />

          <Row>
            <Col xl="12">
              <Card className="pagebackground">
                <div
                  className="Bechofy-bg-soft-pink"
                  style={{ height: "60px" }}
                >
                  <div className="row my-1">
                    <div className="col-2">
                      <div className="form-group">
                        <h4
                          className="FilterCard"
                          style={{ marginTop: "10px" }}
                        >
                          Filter Data By
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <Card style={{ padding: "0 20px" }}>
                  <div className="row my-1  ">
                    <div className="col-2">
                      <div className="form-group ">
                        <div class="input-group">
                          <div class="input-group-prepend gridinput">
                            <span class="input-group-text" id="basic-addon1">
                              <span className="">
                                <img
                                  src={Filtericon}
                                  alt="block user"
                                  className="btnicons"
                                ></img>
                              </span>
                            </span>
                          </div>
                          <input
                            type="text"
                            value={this.state.SearchField}
                            onChange={(text) => {
                              this.setState({
                                SearchField: text.target.value,
                              });
                            }}
                            class="form-control gridinput"
                            placeholder="Customer Name / Order Status"
                            // placeholder="Search either Customer Name / Order Status"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-1">
                      <a
                        onClick={() => {
                          if (this.state.SearchField != null) {
                            this.onPostSearch();
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter the name of Customer"
                            );
                          }
                        }}
                        style={{
                          marginTop: "15px",
                          // background: 'white',
                          color: "white",
                          marginLeft: "0px",
                          marginRight: "4px",
                        }}
                        className="btn align-items-center btn Bechofy-btn "
                      >
                        Search
                      </a>
                    </div>

                    <div className="col-2">
                      <div className="form-group ">
                        <DatePicker
                          placeholderText="From Date"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.FromDate}
                          onChange={(date) => {
                            this.setState({
                              FromDate: date,
                            });
                          }}
                          // isClearable
                          className="form-control gridinput"
                          maxDate={new Date()}
                        />
                        <span
                          class="login-icon-change-pass"
                          style={{ bottom: "30px" }}
                        >
                          <img
                            src={Calendericon}
                            alt="datepicker"
                            className="btnicons"
                          ></img>
                        </span>
                      </div>
                    </div>

                    <div className="col-2">
                      <div className="form-group">
                        <DatePicker
                          placeholderText="To Date"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.ToDate}
                          onChange={(date) => {
                            this.setState({
                              ToDate: date,
                            });
                          }}
                          // isClearable
                          className="form-control gridinput"
                          maxDate={new Date()}
                        />
                        <span
                          class="login-icon-change-pass"
                          style={{ bottom: "30px" }}
                        >
                          <img
                            src={Calendericon}
                            alt="datepicker"
                            className="btnicons"
                          ></img>
                        </span>
                      </div>
                    </div>

                    <div className="col-1">
                      <div className="form-group mt-3">
                        <select
                          className="form-control"
                          value={this.state.year}
                          name="year"
                          select
                          label="Select Year"
                          // onChange={(e) => {
                          //   this.setState(
                          //     year(e.target.value)
                          //   );
                          // }}
                          onChange={this.handleYearChange}
                        >
                          {this.state.theDates.map((val) => (
                            <option
                              className="expance-dropdown-values"
                              value={val}
                            >
                              {val}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-3">
                      <a
                        onClick={() => {
                          // if (
                          //   this.state.FromDate != null ||
                          //   this.state.ToDate != null
                          // )
                          // {

                          if (
                            this.state.FromDate != null &&
                            this.state.ToDate != null
                          ) {
                            this.onPost();
                          } else {
                            this.setState({ selectedYear: this.state.year });
                          }

                          // } else {
                          //   Notiflix.Notify.Failure(
                          //     "Please select from and to date."
                          //   );
                          // }
                        }}
                        style={{
                          marginTop: "15px",
                          // background: 'white',
                          color: "white",
                          marginLeft: "0px",
                          marginRight: "4px",
                        }}
                        className="btn align-items-center btn Bechofy-btn "
                      >
                        Filter & Display
                      </a>
                      {/* <button onClick={()=>{this.setState({ selectedYear:this.state.year,})}}>test</button> */}
                      <a
                        onClick={() => {
                          Notiflix.Loading.Dots("Please wait...");

                          PostApiCall.postRequest(
                            {
                              WhereClause: "",
                              RecordCount: "*",
                            },
                            "Get_Visitor"
                          ).then((results) =>
                            // const objs = JSON.parse(result._bodyText)
                            results.json().then((obj) => {
                              if (
                                results.status == 200 ||
                                results.status == 201
                              ) {
                                // //console.log(obj.data)
                                this.setState({
                                  VisitorData: obj.data,
                                });
                                Notiflix.Loading.Remove();
                              }
                            })
                          );
                        }}
                        style={{
                          marginTop: "15px",
                          // background: 'white',
                          color: "white",
                          marginLeft: "0px",
                          marginRight: "4px",
                        }}
                        className="btn align-items-center btn Bechofy-btn "
                      >
                        List All Orders
                      </a>
                      <a
                        className="btn align-items-center btn Bechofy-btn "
                        style={{
                          marginTop: "15px",
                        }}
                        onClick={() => {
                          window.location.reload();
                        }}
                      >
                        <span className="">
                          <img
                            src={Refreshicon}
                            alt="block user"
                            className="btnicons"
                            style={{ marginRight: "5px" }}
                          ></img>{" "}
                        </span>
                      </a>
                    </div>

                    <div className="col-1 mt-1">
                      <a className="btn align-items-center">
                        <Button
                          className="mx-3"
                          variant="primary"
                          onClick={() => this.setState({ ShowModal: true })}
                        >
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip {...props}>Add Visitor</Tooltip>
                            )}
                            placement="bottom"
                          >
                            <span>
                              <BiUserPlus style={{ fontSize: "21px" }} />
                            </span>
                          </OverlayTrigger>
                        </Button>
                      </a>
                    </div>
                  </div>
                </Card>

                <Card>
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ height: "60px" }}
                  >
                    <div className="row my-1 justify-content-between">
                      <div className="col-2">
                        <div className="form-group">
                          <h4
                            className="FilterCard"
                            style={{ marginTop: "10px" }}
                          >
                            Visitor List
                          </h4>
                        </div>
                      </div>

                      <div className="col-10 text-right">
                        <a
                          onClick={this.DownloadCSV.bind(this)}
                          style={{
                            marginTop: "10px",
                            background: "white",
                            color: "grey",
                            marginRight: "20px",
                          }}
                          className="btn align-items-center btn "
                        >
                          <span className="">
                            <img
                              src={Downloadicon}
                              alt="block user"
                              className="btnicons"
                              style={{ marginRight: "5px" }}
                            ></img>{" "}
                          </span>
                          Download List (.csv)
                        </a>
                      </div>
                      <CSVLink
                        data={this.state.fileData}
                        // filename="OrderList.csv"
                        filename="Visitors.csv"
                        className="hidden"
                        ref={this.csvLink}
                        target="_blank"
                      />
                    </div>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <MDBCol
                        md="12"
                        style={{ marginBottom: "15px", marginTop: "15px" }}
                      >
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">
                              <span className="">
                                <img
                                  src={Searchicon}
                                  alt="block user"
                                  className="btnicons"
                                ></img>
                              </span>
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Type the search phrase you wish to search within the grid"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            onChange={(e) => this.seachBarHandler(e)}
                            value={this.state.searchInput}
                          />
                        </div>
                      </MDBCol>

                      <Col md="12">
                        {/* <MDBDataTableV5
																// scrollX
																// scrollY
																// maxHeight="200px"
																striped
																bordered
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																> */}
                        {this.state.VisitorData.length != 0 ? (
                          <MDBDataTable
                            hover
                            // scrollY
                            striped
                            bordered
                            data={data}
                            entriesOptions={[10, 20, 40, 60, 80, 100, 120, 140]}
                          >
                            <MDBTableHead columns={data.columns} />
                            <MDBTableBody rows={data.rows} />
                          </MDBDataTable>
                        ) : (
                          <p>No Data Found</p>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal class="modal-content" open={this.state.ShowModal} center>
          <div class="px-0 col-12 m-0 d-flex align-items-center justify-content-between">
            <h4 class="text-white py-3 ps-3">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  style={{ border: "1px solid #f7904c", background: "#f7904c" }}
                >
                  {this.state.source == ""
                    ? "Select Source"
                    : this.state.source}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => this.setState({ source: "HIDE" })}
                  >
                    Hide
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => this.setState({ source: "IFEX" })}
                  >
                    IFEX
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </h4>

            <span
              style={{
                color: "grey",
                fontSize: "18px",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({
                  ShowModal: false,
                  userid: "",
                  firstname: "",
                  mobile1: "",
                  emailid: "",
                  designation: "",
                  company: "",
                  source: "",
                });
              }}
            >
              {" "}
              <ImCross />
            </span>
          </div>

          <Row>
            <Col xl="12">
              <form>
                <div className="row g-3 mt-3">
                  <div className="col-12 col-md-6 col-lg-6 col-sm-12 mt-3">
                    <label for="name" className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstname"
                      value={this.state.firstname}
                      onChange={(e) =>
                        this.setState({
                          firstname: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-sm-12 mt-3">
                    <label for="designation" className="form-label">
                      Designation
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="designation"
                      value={this.state.designation}
                      onChange={(e) =>
                        this.setState({
                          designation: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-sm-12 mt-3">
                    <label for="company" className="form-label">
                      Company
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="company"
                      value={this.state.company}
                      onChange={(e) =>
                        this.setState({ company: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-sm-12 mt-3">
                    <label for="number" className="form-label">
                      Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="mobile1"
                      value={this.state.mobile1}
                      onChange={(e) => {
                        if (e.target.value.length <= 10)
                          this.setState({ mobile1: e.target.value });
                      }}

                      // onChange={(e) =>
                      //   if (e.target.value.length <= 10)
                      //   this.setState({ mobile1: e.target.value })
                      // }
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 col-sm-12 mt-3">
                    <label for="email" className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="emailid"
                      value={this.state.emailid}
                      onChange={(e) =>
                        this.setState({ emailid: e.target.value })
                      }
                    />
                  </div>

                  <div className="col-12 my-3">
                    <button
                      type="button"
                      className="btn btnformhome"
                      onClick={() => {
                        if (this.state.firstname != "") {
                          if (this.state.mobile1 != "") {
                            if (this.state.mobile1.length == 10) {
                              if (this.state.emailid != "") {
                                this.visitoradmin();
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter your Email"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure(
                                "Please enter valid mobile number"
                              );
                            }
                          } else {
                            Notiflix.Notify.Failure(
                              "Please enter your mobile number"
                            );
                          }
                        } else {
                          Notiflix.Notify.Failure("Please enter your name");
                        }
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }
}
export default OrderList;
