import React, { Component } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import logo from "../../assets/images/logo.png";

//i18n
import { withNamespaces } from "react-i18next";
import GETApiCall from "../../GETAPI";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReport: false,
      Menu: [
        {
          fld_menuname: "Dashboard",

          fld_pagename: "/dashboard",

          fld_show: "1",

          fld_menuid: 1,
        },

        {
          fld_menuname: "Visitor Management",

          fld_pagename: "/visitor-list",

          fld_show: "1",

          fld_menuid: 2,
        },

        {
          fld_menuname: "Exhibitor Enquiry",

          fld_pagename: "/exhibitor-list",

          fld_show: "1",

          fld_menuid: 3,
        },
        {
          fld_menuname: "Exhibitor Management",

          fld_pagename: "/exhibitor-management",

          fld_show: "1",

          fld_menuid: 3,
        },

        // {
        //   fld_menuname: "Item Master Management",

        //   fld_pagename: "/itemMaster",

        //   fld_show: "1",

        //   fld_menuid: 4,
        // },

        // {
        //   fld_menuname: "Customer Management",

        //   fld_pagename: "/customer-list",

        //   fld_show: "1",

        //   fld_menuid: 4,
        // },

      ],

      SubMenu: {
        Dashboard: [],
        Managements: [
          // {
          //     "fld_menuname": "User Master",

          //     "fld_pagename": "/userMaster",

          //     "fld_show": "1",

          //     "fld_parentid": 4
          // },
          // {
          //     "fld_menuname": "Client Master",

          //     "fld_pagename": "/clientMaster",

          //     "fld_show": "1",

          //     "fld_parentid": 4
          // },
          // {
          //     "fld_menuname": "Employee Master",

          //     "fld_pagename": "/employeemaster",

          //     "fld_show": "1",

          //     "fld_parentid": 4
          // },
          {
            fld_menuname: "Enquiries",

            fld_pagename: "/mailer/1",

            fld_show: "1",

            fld_parentid: 4,
          },
        ],

        // "Sales Management": [
        //     {
        //         "fld_menuname": "Data Capture",

        //         "fld_pagename": "/dataCapture",

        //         "fld_show": "1",

        //         "fld_parentid": 1
        //     },

        // ]
      },

      childMenuDetails: {
        "Masters Management": [
          {
            fld_menuname: "Currency Master",

            fld_pagename: "/currencyMaster",

            fld_show: "1",

            fld_parentid: 4,
          },
          {
            fld_menuname: "News Master",

            fld_pagename: "/newsMaster",

            fld_show: "1",

            fld_parentid: 4,
          },
          {
            fld_menuname: "Career Master",

            fld_pagename: "/careerMaster",

            fld_show: "1",

            fld_parentid: 4,
          },
          {
            fld_menuname: "Contact Master",

            fld_pagename: "/contactMaster",

            fld_show: "1",

            fld_parentid: 4,
          },
          {
            fld_menuname: "Tarriff & Term Condition",

            fld_pagename: "/tarriffMaster",

            fld_show: "1",

            fld_parentid: 4,
          },
          //     {  "fld_menuname": "Contact Details",

          // "fld_pagename": "/contactDetails",

          // "fld_show": "1",

          // "fld_parentid": 4},
          //     {  "fld_menuname": "Coustomer Master",

          // "fld_pagename": "/coustomerMaster",

          // "fld_show": "1",

          // "fld_parentid": 4},
          //     {  "fld_menuname": "Controlls Master",

          // "fld_pagename": "/controllsMaster",

          // "fld_show": "1",

          // "fld_parentid": 4},
        ],
      },
    };
  }

  componentDidMount() {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }

    //   GETApiCall.getrequesturl('Get_MenuList').then(response =>response.json().then(obj=>{
    //       console.log(obj.data);
    //       this.setState({...this.state,
    //     menuDetails:obj.data})

    //    let tempArray =[]
    //    obj.data.map((item,i)=>{

    //        tempArray.push()
    //    })
    //   }))
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {

    // console.log(this.state.M)

    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu py-4"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav ">
                  {this.state.Menu &&
                    this.state.Menu.map((data, index) => {
                      return (
                        <li className="nav-item dropdown">
                          {data.fld_pagename != "/#" ? (
                            <Link
                              to={`${data.fld_pagename}`}
                              className="nav-link dropdown-toggle arrow-none"
                            >
                              {this.props.t(`${data.fld_menuname}`)}
                            </Link>
                          ) : (
                            <li className="nav-item dropdown">
                              <a className="nav-link dropdown-toggle arrow-none">
                                {this.props.t(`${data.fld_menuname}`)}{" "}
                                <div className="arrow-down"></div>
                              </a>

                              <div
                                className={classname("dropdown-menu", {
                                  show: this.state.uiState,
                                })}
                              >
                                {this.state.SubMenu[data.fld_menuname] ==
                                  undefined ? (
                                  <span></span>
                                ) : this.state.SubMenu[data.fld_menuname]
                                  .length > 0 ? (
                                  <span>
                                    {this.state.SubMenu[data.fld_menuname] ==
                                      undefined ? (
                                      <span></span>
                                    ) : (
                                      this.state.SubMenu[data.fld_menuname].map(
                                        (submenu, i) => (
                                          // submenu.fld_show == 1 && submenu.fld_access == 1 ?
                                          <Link
                                            to={`${submenu.fld_pagename}`}
                                            className="dropdown-item"
                                          >
                                            {this.props.t(
                                              `${submenu.fld_menuname}`
                                            )}
                                          </Link>
                                        )
                                      )
                                    )}
                                  </span>
                                ) : (
                                  <p></p>
                                )}
                              </div>
                            </li>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withNamespaces()(Navbar));
