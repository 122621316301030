import React, { Component } from "react";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from "moment";

import ReactApexChart from "react-apexcharts";

class EnquiryGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      GraphData: null,
      series: [
        {
          name: "Month Wise Enquiry Count",
          data: [],
        },
      ],
      options: {
        chart: {
          events: {
            click: function (chart, w, e) {
              console.log(chart, w, e);
            },
          },
        },
        colors: ["#556ee6", "#f1b44c", "#34c38f"],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],

          labels: {
            style: {
              colors: ["#556ee6", "#f1b44c", "#34c38f"],
              fontSize: "12px",
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                chart: {
                  width: 200,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));

    PostApiCall.postRequest(
      {
        clientId: loginDetails[0].fld_userid,
      },
      "getEnquiryGraph"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          GraphData: obj.data,
        });
          console.log(obj.data);
        const categories = [1, 2, 3, 4, 5, 6, 7, , 8, 9, 10, 11, 12];

        this.setState({
          categories: categories,
        });

        const data = new Array(12).fill(0);

        for (var j = 0; j < categories.length; j++) {
          for (var i = 0; i < obj.data.length; i++) {
            if (obj.data[i].month == categories[j] && obj.data[i].year == moment().format("YYYY")) {
              data[j] = data[j] + obj.data[i].enq_count;
            }
          }
        }

        this.setState({ series: [...this.state.series, { name: "Enquiry Count", data: data }] });
      })
    );
  }

  render() {
    return (
      <div id="chart">
        <h4 className="mb-0">Order Chart </h4>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          width={900}
          height={250}
          type="bar"
        />
      </div>
    );
  }
}

export default EnquiryGraph;
