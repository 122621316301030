import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";

import { Link } from "react-router-dom";

class ClientMaster extends Component {
  state = {
    dateInput: {
      value: "",
      error: "",
      touched: false,
    },

    fromCurrency: {
      value: "",
      error: "",
      touched: false,
    },
    toCurrency: {
      value: "",
      error: "",
      touched: false,
    },
    rate: {
      value: "",
      error: "",
      touched: false,
    },
    sellrate: {
      value: "",
      error: "",
      touched: false,
    },

    currencyInfo: [],
    slectCurrencyInfo: [],
  };


  render() {
    // console.log(this.state.inputList)
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={"Global Trendz "}
              breadcrumbItem={"Client Master"}
            />

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="gtrendz-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="gtrendz-text  p-3">
                          <h5 className="gtrendz-text">
                            Client Master List
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
                <Card>
                  <CardBody className="my-1">
                    <Row>


                      <div className="col-12 ">

                        <div className="position-relative div_margin_left">
                          <Link
                            to="/addClient"
                            style={{ float: "right" }}
                            className=" btn gtrendz-btn "
                          >
                            Add{" "}
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="pt-0">
                    <Row>
                      <Col md="12" xs="12">
                        <table className="table px-0">
                          <thead>
                            <tr>
                              <th className="text-center" scope="col">
                                S.No.
                              </th>
                              <th className="text-center" scope="col">
                                Name
                              </th>
                              <th className="text-center" scope="col">
                                Date
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center" scope="col">
                                1
                              </td>
                              <td className="text-center" scope="col">
                                Deepanshi
                              </td>
                              <td className="text-center" scope="col">
                                2-4-2021
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default ClientMaster;