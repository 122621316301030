import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "react-datepicker/dist/react-datepicker.css";

import Notiflix from "notiflix";
import POSTApiCall from "../../Api";
// import Notiflix from "notiflix";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Password: "",
      Manager: "",
      isPasswordVisible: false,
      Email: "",
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#fa6e03",
    });
    // Notiflix.Loading.Standard();
  }

  SaveUser() {
    if (this.state.Name !== "") {
      if (this.state.Password !== "") {
        if (this.state.Email !== "") {
          if (this.state.EmailRegex.test(this.state.Email)) {
            this.onPost();
          } else {
            Notiflix.Notify.Failure("Please enter valid email address");
          }
        } else {
          Notiflix.Notify.Failure("Please enter your email address");
        }
      } else {
        Notiflix.Notify.Failure("Please enter your password");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your name");
    }
  }

  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");

    POSTApiCall.postRequest(
      {
        name: this.state.Name,
        email: this.state.Email,
        password: this.state.Password,
      },
      "Add_User"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("User is successfully added.");
        } else {
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  onchangeName(text) {
    this.setState({
      Name: text.target.value,
    });
  }

  onchangePassword(text) {
    this.setState({
      Password: text.target.value,
    });
  }

  onchangeManager(text) {
    this.setState({
      Manager: text.target.value,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={"Global Trendz "} breadcrumbItem={"Add User"} />

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="gtrendz-bg-soft-pink">
                    <Row>
                      <Col xs="9">
                        <div className="gtrendz-text  p-3">
                          <h5 className="gtrendz-text">Add User Master</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <CardBody className="pt-0" style={{ height: "100%" }}>
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-4">
                                  <label>Name</label>
                                  <input
                                    className="form-control"
                                    value={this.state.Name}
                                    onChange={this.onchangeName.bind(this)}
                                  ></input>
                                </div>
                                <div className="col-md-4">
                                  <label>Password</label>
                                  <input
                                    className="form-control"
                                    type={
                                      this.state.isPasswordVisible
                                        ? "text"
                                        : "password"
                                    }
                                    value={this.state.Password}
                                    onChange={this.onchangePassword.bind(this)}
                                  ></input>
                                  <span>
                                    <i
                                      className="fa fa-eye"
                                      onClick={() => {
                                        this.setState({
                                          isPasswordVisible:
                                            !this.state.isPasswordVisible,
                                        });
                                      }}
                                      style={{
                                        float: "right",
                                        bottom: "12px",
                                        position: "absolute",
                                        right: "20px",
                                      }}
                                    ></i>
                                  </span>
                                </div>
                                <div className="col-md-4">
                                  <label>Email</label>
                                  <input
                                    className="form-control"
                                    value={this.state.Email}
                                    onChange={(test) => {
                                      this.setState({
                                        Email: test.target.value,
                                      });
                                    }}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div>
                      <Button
                        style={{ float: "right" }}
                        className=" btn gtrendz-btn "
                        onClick={this.SaveUser.bind(this)}
                      >
                        Submit{" "}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddUser;
