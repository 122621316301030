import Notiflix from 'notiflix';
import React, { Component } from 'react';
import PostApiCall from "../../Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default class MailerFormBooking extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Name: "",
            Email: "",
            Mobile: "",
            Message: "",
            CaptchaInput: "",
            CaptchaData: "",
            mailtype: this.props.match.params.mailtype,
            Color: "",
            ClientId: this.props.match.params.clientid,
            ClientName: [],
            City: "",
            Country: "",
            ArrivalDate: "",
            ArrivalTime: "",
            DepartureDate: "",
            Adults: "",
            Kids: "",
            PickupRequired: "",
            NumRegex: /^[0-9]*$/,
            EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        }
    }

    componentDidMount() {

        this.GenerateCaptcha();
        PostApiCall.postRequest({
            clientid: this.props.match.params.clientid
        }, "Get_ClientData").then((resultcategory =>

            resultcategory.json().then(obj => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    this.setState({
                        ClientName: obj.data[0].fld_client_name,
                        Color: obj.data[0].fld_color
                    })
                    console.log(obj.data[0].fld_color);




                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj.data);

                }
            })
        ))

    }

    GenerateCaptcha() {
        var chr1 = Math.ceil(Math.random() * 10) + '';
        var chr2 = Math.ceil(Math.random() * 10) + '';


        var str = new Array(4).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); });
        var captchaCode = str + chr1 + chr2
        this.setState({ CaptchaData: captchaCode })

    }


    sendEnquiry(e) {

        e.preventDefault()

        if (this.state.Name != "") {
            if (this.state.Email != "") {
                if (this.state.EmailRegex.test(this.state.Email)) {
                    if (this.state.Mobile != "") {
                        if (this.state.Mobile.length == 10) {
                            if (this.state.City != "") {
                                if (this.state.Country != "") {
                                    if (this.state.CaptchaInput != "") {
                                        if (this.state.CaptchaData == this.state.CaptchaInput) {
                                            this.OnPostSend()
                                        } else {
                                            Notiflix.Notify.Failure("Please Enter Valid Captcha")
                                        }
                                    } else {
                                        Notiflix.Notify.Failure("Please Enter Captcha")
                                    }
                                }
                                else {
                                    Notiflix.Notify.Failure("Please Enter Country Name")

                                }
                            }
                            else {
                                Notiflix.Notify.Failure("Please Enter City Name")

                            }
                        } else {
                            Notiflix.Notify.Failure("Please Enter Mobile")
                        }
                    } else {
                        Notiflix.Notify.Failure("Please Enter Mobile")
                    }
                } else {
                    Notiflix.Notify.Failure("Please Enter Valid Email ")
                }
            } else {
                Notiflix.Notify.Failure("Please Enter Email")
            }
        } else {
            Notiflix.Notify.Failure("Please Enter Your Name")
        }
    }



    OnPostSend() {
        Notiflix.Loading.Init({
            svgColor: '#fa6e03'

        });

        Notiflix.Loading.Dots('Please wait! We are sending your enquiry.');
        PostApiCall.postRequest({
            email: this.state.Email,
            companyname: this.state.CompanyName,
            name: this.state.Name,
            mobile: this.state.Mobile,
            message: this.state.Message,
            clientid: this.state.ClientId,
            mailtype: this.state.mailtype,
            city: this.state.City,
            country: this.state.Country,
            arrivaldate: this.state.ArrivalDate,
            arrivaltime: this.state.ArrivalTime,
            departuredate: this.state.DepartureDate,
            adults: this.state.Adults,
            kids: this.state.Kids,
            pickuprequired: this.state.PickupRequired
        }, "SendMail").then((mail =>
            mail.json().then(obj => {
                if (mail.status == 200 || mail.status == 201) {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success('Thank you for contacting us');
                    //window.location.href = `/mailer/${this.state.ClientId}`;
                    // console.log(obj.data)
                    this.GenerateCaptcha();

                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj.data);

                }
            })
        ))
    }




    render() {
        // console.log(this.state.captchaData)
        return (
            <div className='col-md-12'>
                <form class="row m-3 ">
                    <div class="col-md-6 mb-3">
                        <label for="name" class="form-label">Full Name <span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" id="name" value={this.state.Name} onChange={e => this.setState({ Name: e.target.value })} />
                    </div>
                    {/* <div class="col-md-6 mb-3">
                        <label for="cname" class="form-label">Company Name</label>
                        <input type="text" class="form-control" id="cname" value={this.state.CompanyName} onChange={e => this.setState({ CompanyName: e.target.value })} />
                    </div> */}
                    <div class="col-md-6 mb-3">
                        <label for="email" class="form-label">Email Id <span class="text-danger"> *</span></label>
                        <input type="email" class="form-control" id="email" value={this.state.Email} onChange={e => this.setState({ Email: e.target.value })} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="mobile" class="form-label">Mobile No. <span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" id="mobile" value={this.state.Mobile} onChange={(mobile) => {
                            if (this.state.NumRegex.test(mobile.target.value) && mobile.target.value.length <= 10) {
                                this.setState({ Mobile: mobile.target.value });
                            }
                        }} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="arrivaldate" class="form-label">Arrival date <span class="text-danger"> *</span></label>
                        <DatePicker
                            className="form-control"
                            selected={this.state.ArrivalDate}
                            onChange={(arrivaldate) => {
                                this.setState({ ArrivalDate: arrivaldate.target.value })
                            }}
                        />
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="arrivaltime" class="form-label">Arrival Time <span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" id="arrivaltime" value={this.state.ArrivalTime} onChange={(arrivaltime) => {
                            this.setState({ ArrivalTime: arrivaltime.target.value });

                        }} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="mobile" class="form-label">Departure Date <span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" id="departuredate" value={this.state.DepartureDate} onChange={(departuredate) => {
                            this.setState({ DepartureDate: departuredate.target.value });

                        }} />
                    </div>

                    <div class="col-md-6 mb-3">
                        <label for="mobile" class="form-label">Adults <span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" id="adults" value={this.state.Adults} onChange={(adults) => {
                            this.setState({ Adults: adults.target.value });

                        }} />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="mobile" class="form-label">Kids <span class="text-danger"> *</span></label>
                        <input type="text" class="form-control" id="kids" value={this.state.Kids} onChange={(kids) => {
                            this.setState({ Kids: kids.target.value });

                        }} />
                    </div>




                    <div class="col-md-12 mb-3">
                        <label for="message">Message</label>
                        <div class="form-floating">
                            <textarea class="form-control" placeholder="Leave a comment here" id="message" value={this.state.Message} onChange={e => this.setState({ Message: e.target.value })} style={{ height: "50px" }}></textarea>
                        </div>
                    </div>



                    <div className='col-md-6 mb-3'>
                        <label for="captcha"> {this.state.CaptchaData}</label>
                        <input
                            type="captcha"
                            class="form-control border"
                            placeholder="Enter the captcha"
                            value={this.state.CaptchaInput}
                            onChange={(text) => {
                                this.setState({
                                    CaptchaInput: text.target.value,
                                });
                            }}

                        />

                    </div>
                    <div className='col-md-6 mb-3' onClick={this.sendEnquiry.bind(this)}
                        dangerouslySetInnerHTML={{ __html: this.state.Color }}>
                    </div>


                    <div className='col-md-12 '>
                        <i class="notetext">Note: Your contact details will be used by {this.state.ClientName} to get in touch with you and to send you personalised offers. </i>



                    </div>
                </form>
            </div>
        )
    }
}