import React, { Component } from "react";
import { MDBCol } from "mdbreact";
import { CSVLink } from "react-csv";
import { Row, Col, Card, CardBody, Media } from "reactstrap";
import { Link } from "react-router-dom";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from "moment";

class SocialSource extends Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      EnquiryListData: [],
      fileData: [],
      startDate: null,
      endDate: null,
      searchInput: "",
      OrderData : []
    };
  }


  componentDidMount() {
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));

    PostApiCall.postRequest(
      {
        WhereClause: 'where ',
      },
      "getorderheader"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj.data)

          console.log(new Date("2022-05-28").toLocaleDateString())

          console.log(new Date("2022-05-28").toLocaleDateString())

          this.setState({
            OrderData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    )
  }

  onPost() {
    Notiflix.Loading.Dots("Please wait...");
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));
    PostApiCall.postRequest(
      {
        WhereClause: `where FLD_CLIENT_ID=${loginDetails[0].fld_userid
          } AND cast (fld_created_on as date) BETWEEN cast('${moment(
            this.state.startDate == null ? null : this.state.startDate
          ).format("MM-DD-YYYY")}' as date)
    AND cast('${moment(
            this.state.endDate == null ? null : this.state.endDate
          ).format("MM-DD-YYYY")}' as date)
    `,
      },
      "getEnquiryData"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj.data);
          this.setState({
            EnquiryListData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  //   Search bar
  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };

  // Date handler

  dateHandler = (e) => {
    e.preventDefault();
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        RecordCount: "*",
        WhereClause: `where cast (FLD_CREATED_ON as date) BETWEEN cast('${moment(
          this.state.startDate == null ? null : this.state.startDate
        ).format("YYYY-MM-DD")}' as date)
        AND cast('${moment(
          this.state.endDate == null ? null : this.state.endDate
        ).format("YYYY-MM-DD")}' as date)
        `,
      },
      "GET_DATA_BY_DATE"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            EnquiryListData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  };

  // download

  DownloadCSV = () => {
    this.setState(
      {
        fileData: this.state.EnquiryListData,
      },
      () => {
        setTimeout(() => {
          this.csvLink.current.link.click();
        });
      }
    );
  };

  //   search bar

  render() {
    return (
      <React.Fragment>
        <Card className="mini-stats-wid" style={{ height: "457px" }}>
          <CardBody>
            <Media>
              <Media body>
                <Row>
                  <Col xl="12">
                    <Card className="overflow-hidden">
                      <div className="Bechofy-bg-soft-pink">
                        <Row>
                          <Col xs="6">
                            <div className="Bechofy-text p-3">
                              <h5 className="Bechofy-text text-white">
                                Orders
                              </h5>
                            </div>
                          </Col>
                        </Row>
                      </div>

                    </Card>
                  </Col>
                </Row>

                <div
                  class="table-wrapper-scroll-y my-custom-scrollbar"
                  style={{ maxHeight: "310px" }}
                >
                  <table class="table table-bordered table-striped mb-0">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Order Id</th>
                        <th>Order Date</th>
                        <th>Customer Name</th>
                        <th>Customer Number</th>
                        <th>No. of Items</th>
                        <th>Order Status</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.OrderData.map((data, i) => (
                        <tr>
                          <td className="centered">{i + 1}</td>
                          <td className="leftAlign">{data.fld_order_id}</td>
                          <td className="centered">{data.fld_order_date.split("T")[0]}</td>
                          <td className="centered">{data.fld_customer_name}</td>
                          <td className="centered">{data.fld_customer_number}</td>
                          <td className="centered">{data.fld_city}</td>
                          <td className="rightAlign">{data.fld_status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default SocialSource;
