import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
// import { Link } from "react-router-dom";
// import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
// import Notiflix from "notiflix";
// import PostApiCall from "../../Api";

//import Charts
// import StackedColumnChart from "./StackedColumnChart";

// import modalimage1 from "../../assets/images/product/img-7.png";
// import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import GoogleAnalytics from "./GoogleAnalytics";
import EnquiryGraph from "./EnquiryGraph";
import EnquiryGrid from "./EnquiryList";
import Notiflix from "notiflix";
import PostApiCall from "../../Api"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withNamespaces } from "react-i18next";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      qrdata: "",
      CountryData: [],
      EmailRegex:
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      NumRegex: /^[0-9]*$/,
      name: "",
      mobile: "",
      emailid: "",
      designation: "",
      company: "",
      userid: "",
    };
  }


//   generateQR = async text => {
//     try {
//         return await QRCode.toDataURL(text)

//     } catch (err) {
//         console.error(err)
//     }
// }


  componentDidMount() {
    var detail = JSON.parse(localStorage.getItem("LoginDetail"));
    if (detail != null) {
      this.setState({
        userid: detail[0].fld_userid,
      });
    }
  }

  render() {
    // console.log(this.state.OrderList)

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title={"Dashboard"} />

            <Row>
              <Col xl="4">
                <WelcomeComp />
              </Col>
              <Col xl="8">
                <Row>
                  {/* <Card>
                                        <CardBody style={{ height: '322px' }}>
                                            <EnquiryGraph />
                                        </CardBody>

                                    </Card> */}
                </Row>
              </Col>
            </Row>

          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Dashboard);
