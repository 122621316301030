import React, { Component } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";
import Notiflix from "notiflix";
import NotificationIcon from '../../assets/images/dashboardimages/notification.png'
import ChngPswdIcon from '../../assets/images/dashboardimages/change-password.png'
import LogoutIcon from '../../assets/images/dashboardimages/logout.png'
import UserIcon from '../../assets/images/dashboardimages/profile.png'

import user1 from '../../assets/images/helpcenter.png';

class WelcomeComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Details: [],
            Logintime: ''
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            customSvgUrl: 'http://backoffice.Bechofy/static/media/logo.b5951bfc.png',
            svgSize: '80px',
        });

        // Notiflix.Loading.custom("");


        var login = localStorage.getItem('LoginDetail');
        var details = JSON.parse(login)
        // //console.log(details)


        var lastlogin = localStorage.getItem('lastlogin');
        var LastDateTime = JSON.parse(lastlogin)

        // //console.log(LastDateTime)
        this.setState({
            Details: details[0],
            Logintime: LastDateTime
        })

    }



    render() {
        // //console.log(this.state.Details.fld_name)
        return (
            <React.Fragment>
                <Card className="overflow-hidden" style={{ height: '340px' }}>
                    <div className="Bechofy-bg-soft-pink">
                        <Row>
                            <Col xs="7">
                                <div className="Bechofy-text p-3">
                                    <h5 className="Bechofy-text">Welcome {this.state.Details.fld_name} !</h5>
                                    <p>{this.state.Details.fld_designation}</p>

                                </div>
                            </Col>
                            <Col xs="5" className="align-self-end">
                                <img src={profileImg} alt="" className="img-fluid" />
                            </Col>
                        </Row>
                    </div>
                    <CardBody className="pt-0">
                        <Row>
                            <Col sm="6">
                                <div className="avatar-md profile-user-wid mb-4 user_profile" >
                                    {/* <img src={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_storelogo} alt="" className="img-thumbnail rounded-circle" style={{ height: '100px' }} /> */}
                                </div>
                                {/* <h5 className="font-size-15 text-truncate">{this.state.Details.fld_name}</h5> */}
                            </Col>

                            <Col sm="6">
                                <div className="pt-4">
                                    <Row>
                                        <Col xs="12">
                                            <h5 className="font-size-15">Last Login</h5>
                                            <p className="text-muted mb-0"> {this.state.Logintime}</p>
                                        </Col>

                                    </Row>
                                    {/* <div className="mt-4">
                                        <Link to="" className="btn Bechofy-btn waves-effect waves-light btn-sm">View Profile <i className="mdi mdi-arrow-right ml-1"></i></Link>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>

                    <CardBody className="pt-0">

                        <Row>
                            <Col xs="3" className="ProfilesIcon">
                                <img src={UserIcon} data-toggle="tooltip" data-placement="top" title="Profile" alt="profile" ></img>
                                <p>Profile</p>
                            </Col>
                            <Col xs="3" className="ProfilesIcon">

                                <img src={NotificationIcon} data-toggle="tooltip" data-placement="top" title="Notifications" alt="notification" ></img>
                                <p>Notifications</p>
                            </Col>
                            <Col xs="4" className="ProfilesIcon">
                                <a href="/change_password" style={{ color: "#49507D" }}>
                                    <img src={ChngPswdIcon} data-toggle="tooltip" data-placement="top" title="Chanhge Password" alt="change password" ></img>
                                    <p>Change Password</p>
                                </a>
                            </Col>
                            <Col xs="2" className="ProfilesIcon" style={{ cursor: "pointer" }}>
                                <img src={LogoutIcon} data-toggle="tooltip" data-placement="top" title="Logout" alt="logout"
                                    onClick={() => {
                                        localStorage.removeItem('LoginDetail')
                                        localStorage.clear();
                                        window.location.href = '/'
                                    }}></img>
                                <p>Logout</p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default WelcomeComp;
