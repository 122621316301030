import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from "moment";
import Modal from 'react-responsive-modal';
// import { confirmAlert } from "react-confirm-alert";
import Filtericon from '../../assets/images/dashboardimages/filter.png'
import Searchicon from '../../assets/images/dashboardimages/search.png'
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png'
import Calendericon from '../../assets/images/dashboardimages/calendar.png'
import Refreshicon from '../../assets/images/dashboardimages/refresh.png'
// import Viewicon from '../../assets/images/dashboardimages/file.png'
// import Carticon from '../../assets/images/dashboardimages/shopping-cart.png'
// import Eyeicon from '../../assets/images/dashboardimages/eye.png'
// ../../assets/images/dashboardimages/eye.png'

import { ImCross } from 'react-icons/im'

class OrderList extends Component {
    constructor(props) {
        super(props);
        this.csvLink = React.createRef()
        this.state = {
            ShowUpdateOrderModal: false,
            UserData: [],
            Id: "",
            searchInput: "",
            FromDate: null,
            ToDate: null,
            ExhibitorData: [],
            ViewUpdateData: [],
            ItemList: [],
            CustomerData: [],
            SearchField: null,
            fileData: [],
            ShowItemModal: false,
            Item_Ids: [],
            selectedorderid: null,
            UserDepartment: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_department
        };
    }

    componentDidMount() {

        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                WhereClause: ``,
            },
            "Get_Exhibitor"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)
                    this.setState({ ExhibitorData: obj.data, });
                    Notiflix.Loading.Remove();
                }
            })
        )

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                RecordCount: '*',
                WhereClause: `where convert(datetime,fld_order_date,103) BETWEEN cast('${moment(this.state.FromDate == null ? null : this.state.FromDate).format('MM-DD-YYYY')}' as date)
    AND cast('${moment(this.state.ToDate == null ? null : this.state.ToDate).format('MM-DD-YYYY')}' as date)
    `,
            },
            "getorderheader"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //console.log(obj.data)
                    this.setState({
                        ExhibitorData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }


    onPostSearch() {
        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {

                RecordCount: '*',
                WhereClause: `where upper(SearchFiled) LIKE isnull('%${this.state.SearchField == null ? "" : this.state.SearchField}%',upper(SearchFiled))
				`,
            },
            "getorderheader"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //console.log(obj.data)
                    this.setState({
                        ExhibitorData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }

    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    DownloadCSV = () => {

        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {

                WhereClause: '',
                RecordCount: '*'
            },
            "getorderdetails"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length != 0) {
                        // this.props.setstate()
                        this.setState({
                            fileData: obj.data,
                        }, () => {
                            setTimeout(() => {
                                this.csvLink.current.link.click();
                            });
                        });
                    }
                    Notiflix.Loading.Remove();
                }
            }))

    }


    updateOrder() {

        PostApiCall.postRequest(
            {
                orderid: this.state.selectedorderid,
                itemdetails: JSON.stringify(this.state.Item_Ids.map(val => ({ ItemId: val, Status: "Dispatched" })))
            },
            "updateorder"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)

                    this.setState({
                        ShowModal: false
                    });
                    Notiflix.Loading.Remove();
                }
            })
        )
    }



    render() {


        // console.log(this.state.Item_Ids.toString())

        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "sn",
                    // sort: "asc",
                    width: 150,
                },
                {
                    label: "Exhibitor ID",
                    field: "userid",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Company Name",
                    field: "fullname",
                    sort: "disabled",
                    width: 200,
                },
                {
                    label: "Exhibitor From",
                    field: "email",
                    sort: "disabled",
                    width: 100,
                },
                {
                    label: "Created On",
                    field: "create",
                    sort: "disabled",
                    width: 100,
                },
                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ],
            rows: this.state.ExhibitorData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                // if (
                //     this.state.searchInput !== "" &&
                //     (data.customer_name
                //         .toLowerCase()
                //         .includes(this.state.searchInput.toLowerCase())
                //     )
                // ) {
                //     return data;
                // }

                // if (
                //     this.state.searchInput !== "" &&
                //     (data.fld_order_number
                //         .toLowerCase()
                //         .includes(this.state.searchInput.toLowerCase())
                //     )
                // ) {
                //     return data;
                // }




                if (
                    this.state.searchInput !== "" &&
                    String(data.FLD_EXH_ID).toLowerCase().includes(
                        this.state.searchInput.toLowerCase()
                    )
                ) {
                    return data;
                }

                if (
                    this.state.searchInput !== "" &&
                    String(data.FLD_COMPANY_NAME).toLowerCase().includes(
                        this.state.searchInput.toLowerCase()
                    )
                ) {
                    return data;
                }

                if (
                    this.state.searchInput !== "" &&
                    String(data.FLD_CREATED_ON).toLowerCase().includes(
                        this.state.searchInput.toLowerCase()
                    )
                ) {
                    return data;
                }


            }).map((data, i) => {
                return {
                    sn: (i + 1),
                    // orderid: data.fld_order_id,
                    userid: (data.FLD_EXH_ID),
                    fullname: (data.FLD_COMPANY_NAME),
                    email: data.FLD_CLIENT_FROM,
                    // number: (data.FLD_TEL_NUMBER),
                    create: (data.FLD_CREATED_ON.split("T")[0]),
                    action: <span className="" style={{ cursor: "pointer" }}>

                        <a href={`/view-exhibitor/${data.FLD_EXH_ID}`} className="btn align-items-center btn Bechofy-btn mr-3" >View </a>

                    </span>

                }
            })
        }

        return (


            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={('Dashboard')} breadcrumbItem={"Exhibitor List"} />

                    <Row>
                        <Col xl="12">
                            <Card className="pagebackground">
                                <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                    <div className="row my-1">
                                        <div className="col-2">
                                            <div className="form-group">
                                                <h4 className="FilterCard" style={{ marginTop: '10px' }}>Filter Data By</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Card style={{ padding: '0 20px' }}>
                                    <div className="row my-1  ">
                                        <div className="col-4">
                                            <div className="form-group ">

                                                <div class="input-group">
                                                    <div class="input-group-prepend gridinput">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Filtericon} alt="block user" className="btnicons"></img></span>
                                                        </span>
                                                    </div>
                                                    <input type="text"
                                                        value={this.state.SearchField}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                SearchField: text.target.value
                                                            })
                                                        }}
                                                        class="form-control gridinput" placeholder="Search either Customer Name / Order Status" aria-label="Search" aria-describedby="basic-addon1" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <a
                                                onClick={() => {
                                                    if (this.state.SearchField != null) {
                                                        this.onPostSearch()
                                                    }
                                                    else {
                                                        Notiflix.Notify.failure('Please enter the name of Customer')
                                                    }
                                                }}

                                                style={{

                                                    marginTop: "15px",
                                                    // background: 'white',
                                                    color: 'white',
                                                    marginLeft: '0px',
                                                    marginRight: '4px'
                                                }}
                                                className="btn align-items-center btn Bechofy-btn "
                                            >
                                                Search
                                            </a>
                                        </div>
                                        <div className="col-2">



                                            <div className="form-group ">
                                                <DatePicker
                                                    placeholderText="From Date"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.FromDate}
                                                    onChange={(date) => {
                                                        this.setState({
                                                            FromDate: date
                                                        })
                                                    }}
                                                    // isClearable
                                                    className="form-control gridinput"
                                                    maxDate={new Date()}
                                                />
                                                <span class="login-icon-change-pass" style={{ bottom: '30px' }}>
                                                    <img src={Calendericon} alt="datepicker" className="btnicons"></img>
                                                </span>

                                            </div>
                                        </div>
                                        <div className="col-2">

                                            <div className="form-group">

                                                <DatePicker
                                                    placeholderText="To Date"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.ToDate}
                                                    onChange={(date) => {
                                                        this.setState({
                                                            ToDate: date
                                                        })
                                                    }}
                                                    // isClearable
                                                    className="form-control gridinput"
                                                    maxDate={new Date()}
                                                />
                                                <span class="login-icon-change-pass" style={{ bottom: '30px' }}>
                                                    <img src={Calendericon} alt="datepicker" className="btnicons"></img>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <a
                                                onClick={() => {
                                                    if (this.state.FromDate != null || this.state.ToDate != null) {
                                                        this.onPost()
                                                    }
                                                    else {
                                                        Notiflix.Notify.Failure('Please select from and to date.')

                                                    }

                                                }}

                                                style={{

                                                    marginTop: "15px",
                                                    // background: 'white',
                                                    color: 'white',
                                                    marginLeft: '0px',
                                                    marginRight: '4px'
                                                }}
                                                className="btn align-items-center btn Bechofy-btn "
                                            >
                                                Filter & Display
                                            </a>

                                            <a
                                                onClick={() => {

                                                    Notiflix.Loading.Dots("Please wait...");

                                                    PostApiCall.postRequest(
                                                        {

                                                            WhereClause: '',
                                                            RecordCount: '*'
                                                        },
                                                        "getorderheader"
                                                    ).then((results) =>
                                                        // const objs = JSON.parse(result._bodyText)
                                                        results.json().then((obj) => {
                                                            if (results.status == 200 || results.status == 201) {
                                                                // //console.log(obj.data)
                                                                this.setState({
                                                                    ExhibitorData: obj.data,

                                                                });
                                                                Notiflix.Loading.Remove();
                                                            }
                                                        }))
                                                }}

                                                style={{

                                                    marginTop: "15px",
                                                    // background: 'white',
                                                    color: 'white',
                                                    marginLeft: '0px',
                                                    marginRight: '4px'
                                                }}
                                                className="btn align-items-center btn Bechofy-btn "
                                            >
                                                List All Orders
                                            </a>
                                            <a className="btn align-items-center btn Bechofy-btn "
                                                style={{

                                                    marginTop: "15px",
                                                    // background: 'white',


                                                }}
                                                onClick={() => {
                                                    window.location.reload();
                                                }}
                                            >
                                                <span className=""><img src={Refreshicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                            </a>

                                        </div>


                                    </div>
                                </Card>

                                <Card >
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                        <div className="row my-1">
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px' }}>Exhibitor List</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                <div class="input-group mb-3">

                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}
                                                    />
                                                </div>
                                            </MDBCol>


                                            <Col md="12">
                                                {/* <MDBDataTableV5
																// scrollX
																// scrollY
																// maxHeight="200px"
																striped
																bordered
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																> */}
                                                {this.state.ExhibitorData.length != 0 ?
                                                    <MDBDataTable
                                                        hover
                                                        // scrollY
                                                        striped
                                                        bordered
                                                        data={data}
                                                        seachTop={false}
                                                        entriesOptions={[10, 20, 40, 60, 80, 100, 120, 140]}
                                                    >

                                                        <MDBTableHead columns={data.columns} />
                                                        <MDBTableBody rows={data.rows} />
                                                    </MDBDataTable>
                                                    :
                                                    <p>No Data Found</p>}
                                            </Col>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>

        );
    }
}
export default OrderList;
