

import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import moment from "moment";
import Modal from 'react-responsive-modal';
// import { confirmAlert } from "react-confirm-alert";
import Filtericon from '../../assets/images/dashboardimages/filter.png'
import Searchicon from '../../assets/images/dashboardimages/search.png'
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png'
import Calendericon from '../../assets/images/dashboardimages/calendar.png'
import Refreshicon from '../../assets/images/dashboardimages/refresh.png'
// import ViewUpdateOrder from "./ViewUpdateOrder";
import { ImCross } from 'react-icons/im'



export default class CustomerList extends Component {

    constructor(props) {
        super(props)
        this.state = {

            Id: "",
            searchInput: "",
            FromDate: null,
            ToDate: null,
            CustomerData: [],
            SearchField: null,
            fileData: [],

            NumRegex: /^[0-9]*$/,

            CustomerId: null,
            Name: "",
            mobile: "",
            address: "",
            customerCode: "",

            UserDepartment: JSON.parse(localStorage.getItem("LoginDetail"))[0].fld_department
        }
    }


    fetchCustomerMaster() {

        PostApiCall.postRequest(
            {
                WhereClause: ``,
            },
            "getcustomer"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // console.log(obj.data)
                    this.setState({
                        CustomerData: obj.data,
                    });
                    Notiflix.Loading.Remove();
                }
            })
        )
    }


    componentDidMount() {

        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("Please wait...");

        this.fetchCustomerMaster()

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                RecordCount: '*',
                WhereClause: `where convert(datetime,fld_order_date,103) BETWEEN cast('${moment(this.state.FromDate == null ? null : this.state.FromDate).format('MM-DD-YYYY')}' as date)
    AND cast('${moment(this.state.ToDate == null ? null : this.state.ToDate).format('MM-DD-YYYY')}' as date)
    `,
            },
            "getcustomer"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //console.log(obj.data)
                    this.setState({
                        CustomerData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }


    onPostSearch() {
        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {

                RecordCount: '*',
                WhereClause: `where upper(SearchFiled) LIKE isnull('%${this.state.SearchField == null ? "" : this.state.SearchField}%',upper(SearchFiled))
				`,
            },
            "getcustomer"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //console.log(obj.data)
                    this.setState({
                        CustomerData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }

    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    DownloadCSV = () => {

        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {

                WhereClause: '',
                RecordCount: '*'
            },
            "getorderdetails"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    if (obj.data.length != 0) {
                        // this.props.setstate()
                        this.setState({
                            fileData: obj.data,
                        }, () => {
                            setTimeout(() => {
                                this.csvLink.current.link.click();
                            });
                        });
                    }
                    Notiflix.Loading.Remove();
                }
            }))

    }



    SaveCustomer() {

        if (this.state.Name != "") {
            if (this.state.mobile != "") {
                if (this.state.mobile.length == 10) {
                    if (this.state.address != "") {

                        Notiflix.Loading.Dots("Please wait...");

                        PostApiCall.postRequest(
                            {
                                customerid: this.state.CustomerId,
                                customername: this.state.Name,
                                customeraddress: this.state.address,
                                customernumber: this.state.mobile,
                                status: 'Active',
                                customercode: this.state.customerCode,
                            },
                            "addcustomer"
                        ).then((results) =>
                            // const objs = JSON.parse(result._bodyText)
                            results.json().then((obj) => {
                                if (results.status == 200 || results.status == 201) {
                                    // console.log(obj.data)
                                    this.setState({ ShowModal: false }, () => this.fetchCustomerMaster() );
                                    Notiflix.Loading.Remove();
                                }
                            })
                        )

                    } else {
                        Notiflix.Notify.Failure("Please Enter Address")
                    }
                } else {
                    Notiflix.Notify.Failure("Please Enter Valid Number")
                }
            } else {
                Notiflix.Notify.Failure("Please Enter Mobile")
            }
        } else {
            Notiflix.Notify.Failure("Please Enter Name")
        }
    }



    render() {

        // console.log(this.state.ViewData)

        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "sn",
                    // sort: "asc",
                    width: 150,
                },
                {
                    label: "Customer Id",
                    field: "id",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Customer Code",
                    field: "code",
                    sort: "asc",
                    width: 270,
                },

                {
                    label: "Customer Name",
                    field: "name",
                    sort: "disabled",
                    width: 100,
                },
                {
                    label: "Customer Number",
                    field: "number",
                    sort: "disabled",
                    width: 100,
                },
                {
                    label: "Customer Address",
                    field: "address",
                    sort: "disabled",
                    width: 150,
                },

                {
                    label: "Customer Status",
                    field: "status",
                    sort: "disabled",
                    width: 100,
                },

                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ],
            rows: this.state.CustomerData.map((data, i) => {
                return {
                    sn: (i + 1),
                    id: data.fld_customer_id,
                    code: (data.fld_customer_code),
                    name: (data.fld_customer_name),
                    number: (data.fld_customer_number),
                    address: (data.fld_customer_address),
                    status: (data.fld_status),
                    action: <a className="btn align-items-center btn Bechofy-btn mr-3" onClick={() => this.setState({ CustomerId: data.fld_customer_id, Name: data.fld_customer_name, mobile: data.fld_customer_number, address: data.fld_customer_address, customerCode: data.fld_customer_code, ShowModal: true })} >Edit </a>
                }
            })
        }



        return (
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs breadcrumbItem={"Customer Management"} urlPath={"/order_list"} />

                    <Row>
                        <Col xl="12">
                            <Card className="pagebackground">
                                <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                    <div className="row my-1">
                                        <div className="col-2">
                                            <div className="form-group">
                                                <h4 className="FilterCard" style={{ marginTop: '10px' }}>Filter Data By</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Card style={{ padding: '0 20px' }}>
                                    <div className="row my-1  ">
                                        <div className="col-4">
                                            <div className="form-group ">

                                                <div class="input-group">
                                                    <div class="input-group-prepend gridinput">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Filtericon} alt="block user" className="btnicons"></img></span>
                                                        </span>
                                                    </div>
                                                    <input type="text"
                                                        value={this.state.SearchField}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                SearchField: text.target.value
                                                            })
                                                        }}
                                                        class="form-control gridinput" placeholder="Search either Customer Name / Order Status" aria-label="Search" aria-describedby="basic-addon1" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <a
                                                onClick={() => {
                                                    if (this.state.SearchField != null) {
                                                        this.onPostSearch()
                                                    }
                                                    else {
                                                        Notiflix.Notify.failure('Please enter the name of Customer')
                                                    }

                                                }}

                                                style={{

                                                    marginTop: "15px",
                                                    // background: 'white',
                                                    color: 'white',
                                                    marginLeft: '0px',
                                                    marginRight: '4px'
                                                }}
                                                className="btn align-items-center btn Bechofy-btn "
                                            >
                                                Search
                                            </a>
                                        </div>
                                        <div className="col-2">

                                            <div className="form-group ">
                                                <DatePicker
                                                    placeholderText="From Date"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.FromDate}
                                                    onChange={(date) => {
                                                        this.setState({
                                                            FromDate: date
                                                        })
                                                    }}
                                                    // isClearable
                                                    className="form-control gridinput"
                                                    maxDate={new Date()}
                                                />
                                                <span class="login-icon-change-pass" style={{ bottom: '30px' }}>
                                                    <img src={Calendericon} alt="datepicker" className="btnicons"></img>
                                                </span>

                                            </div>
                                        </div>

                                        <div className="col-2">

                                            <div className="form-group">

                                                <DatePicker
                                                    placeholderText="To Date"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.ToDate}
                                                    onChange={(date) => {
                                                        this.setState({
                                                            ToDate: date
                                                        })
                                                    }}
                                                    // isClearable
                                                    className="form-control gridinput"
                                                    maxDate={new Date()}
                                                />
                                                <span class="login-icon-change-pass" style={{ bottom: '30px' }}>
                                                    <img src={Calendericon} alt="datepicker" className="btnicons"></img>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-3">
                                            <a
                                                onClick={() => {
                                                    if (this.state.FromDate != null || this.state.ToDate != null) {
                                                        this.onPost()
                                                    }
                                                    else {
                                                        Notiflix.Notify.Failure('Please select from and to date.')

                                                    }

                                                }}

                                                style={{

                                                    marginTop: "15px",
                                                    // background: 'white',
                                                    color: 'white',
                                                    marginLeft: '0px',
                                                    marginRight: '4px'
                                                }}
                                                className="btn align-items-center btn Bechofy-btn "
                                            >
                                                Filter & Display
                                            </a>

                                            <a
                                                onClick={() => {

                                                    Notiflix.Loading.Dots("Please wait...");

                                                    PostApiCall.postRequest(
                                                        {

                                                            WhereClause: '',
                                                            RecordCount: '*'
                                                        },
                                                        "getcustomer"
                                                    ).then((results) =>
                                                        // const objs = JSON.parse(result._bodyText)
                                                        results.json().then((obj) => {
                                                            if (results.status == 200 || results.status == 201) {
                                                                // //console.log(obj.data)
                                                                this.setState({
                                                                    CustomerData: obj.data,

                                                                });
                                                                Notiflix.Loading.Remove();
                                                            }
                                                        }))
                                                }}

                                                style={{

                                                    marginTop: "15px",
                                                    // background: 'white',
                                                    color: 'white',
                                                    marginLeft: '0px',
                                                    marginRight: '4px'
                                                }}
                                                className="btn align-items-center btn Bechofy-btn "
                                            >
                                                List All Orders
                                            </a>
                                            <a className="btn align-items-center btn Bechofy-btn "
                                                style={{

                                                    marginTop: "15px",
                                                    // background: 'white',


                                                }}
                                                onClick={() => {
                                                    window.location.reload();
                                                }}
                                            >
                                                <span className=""><img src={Refreshicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                            </a>

                                        </div>


                                    </div>
                                </Card>

                                <Card >
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                        <div className="row my-1">
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px' }}>Customer List</h4>
                                                </div>
                                            </div>

                                            <div className="col-10 d-flex justify-content-end">

                                                <a
                                                    onClick={() => this.setState({ ShowModal: true })}

                                                    style={{

                                                        marginTop: "10px",
                                                        background: 'white',
                                                        color: 'grey',
                                                        marginRight: '20px'
                                                    }}
                                                    className="btn align-items-center btn"
                                                >
                                                    <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
                                                    Add Customer
                                                </a>


                                                <a
                                                    onClick={this.DownloadCSV.bind(this)}
                                                    style={{

                                                        marginTop: "10px",
                                                        background: 'white',
                                                        color: 'grey',
                                                        marginRight: '20px'
                                                    }}
                                                    className="btn align-items-center btn "
                                                >
                                                    <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
                                                    Download List (.csv)
                                                </a>


                                                <CSVLink
                                                    data={this.state.fileData}
                                                    filename="OrderList.csv"
                                                    className="hidden"
                                                    ref={this.csvLink}
                                                    target="_blank"
                                                />

                                            </div>


                                        </div>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>


                                            <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                <div class="input-group mb-3">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span>
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </MDBCol>



                                            <Col md="12">



                                                {this.state.CustomerData.length != 0 ?
                                                    <MDBDataTable
                                                        hover
                                                        // scrollY
                                                        striped
                                                        bordered
                                                        data={data}
                                                        seachTop={false}
                                                        entriesOptions={[10, 20, 40, 60, 80, 100, 120, 140]}
                                                    >

                                                        <MDBTableHead columns={data.columns} />
                                                        <MDBTableBody rows={data.rows} />
                                                    </MDBDataTable>
                                                    :
                                                    <p>No Data Found</p>}
                                            </Col>

                                        </Row>
                                    </CardBody>
                                </Card>
                            </Card>
                        </Col>
                    </Row>
                </Container>




                <Modal class="modal-content" open={this.state.ShowModal} center>


                    <div class=" col-12 m-0 mb-3 d-flex align-items-center justify-content-between bg-secondary">
                        <h4 class="text-white py-2 ps-3">View Order </h4>

                        <span style={{ color: "white", fontSize: "20px", cursor: "pointer" }} onClick={() => {
                            this.setState({ ShowModal: false })
                        }}> <ImCross /></span>
                    </div>


                    <Col md="12">

                        <div className='row m-0 '>

                            <div className="col-md-4 mb-3">
                                <label>
                                    Name <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    className="form-control"
                                    value={this.state.Name}
                                    onChange={(text) => {
                                        this.setState({
                                            Name: text.target.value,
                                        });
                                    }}

                                // disabled={this.state.selectItem}
                                />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label>
                                    Customer Code <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.customerCode}
                                    onChange={(text) => {
                                        this.setState({
                                            customerCode: text.target.value,
                                        });
                                    }}
                                />
                            </div>

                            <div className="col-md-4 mb-3">
                                {" "}
                                <label>
                                    Mobile Number  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.mobile}
                                    onChange={(mobile) => {
                                        if (this.state.NumRegex.test(mobile.target.value) && mobile.target.value.length <= 10) {
                                            this.setState({ mobile: mobile.target.value });
                                        }
                                    }}
                                />
                            </div>

                            <div className="col-md-12 mb-3">
                                {" "}
                                <label>
                                    Address  <span style={{ color: "red" }}>*</span>
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    value={this.state.address}
                                    onChange={(text) => {
                                        this.setState({
                                            address: text.target.value,
                                        });
                                    }}
                                />
                            </div>
                        </div>


                    </Col>

                    <div className="row m-0 justify-content-end">

                        <button class="btn col-2 btn-secondary" onClick={this.SaveCustomer.bind(this)}>Save</button>

                    </div>

                </Modal>


            </div>

        )
    }
}
