import React, { Component } from "react";
import Notiflix from "notiflix";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
} from "reactstrap";
import PostApiCall from "../../Api";
import DatePicker from "react-datepicker";

export class EnquiryDataGrid extends Component {
  constructor() {
    super();
    this.state = {
      EnquiriesData: null,
      Type: "",
    };
  }
  componentDidMount() {
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));
    // Notiflix.Loading.Dots("");
    console.log(loginDetails);
    console.log(loginDetails[0].fld_userid);
    PostApiCall.postRequest(
      {
        clientId: loginDetails[0].fld_userid,
      },
      "getEnquiryData"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState(
          {
            EnquiriesData: obj.data,
          },
          () => {
              console.log(this.state.EnquiriesData,"this is enquiries api")
            Notiflix.Loading.Remove();
          }
        );
      })
    );
  }
  render() {
    return (
      <>
        <Card>
          <CardTitle
            style={{
              padding: "20px",
              marginBottom: "14px",
              border: "none",
            }}
            className="gtrendz-header"
          >
            <h3 style={{color:"#fff"}}> Filter Data By</h3>
          </CardTitle>
          <CardBody>
            <Container fluid>
              <Row>
                <Col lg="4">
                  <Container fluid>
                    <Row>
                      <Col lg="4">
                        <h4>From Date</h4>
                      </Col>
                      <Col lg="8">
                        <DatePicker
                        placeholderText="From date"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.Venuedate}
                          onChange={(date) => {
                            this.setState({
                              Venuedate: date,
                            });
                          }}
                          isClearable
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col lg="4">
                  <Container fluid>
                    <Row>
                      <Col lg="4">
                        <h4>To Date</h4>
                      </Col>
                      <Col lg="8">
                        <DatePicker
                        placeholderText="To date"
                          dateFormat="dd/MM/yyyy"
                          selected={this.state.Venuedate}
                          onChange={(date) => {
                            this.setState({
                              Venuedate: date,
                            });
                          }}
                          isClearable
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col lg="4">
                  <Container fluid>
                    <Row>
                      <Col lg="4">
                        <h4>Select Type</h4>
                      </Col>
                      <Col lg="8">
                        <select
                          className="form-control"
                          value={this.state.Type}
                          onChange={(text) => {
                            this.setState({
                              Type: text.target.value,
                            });
                          }}
                        >
                          <option value="">Select Enquiry Type</option>
                          {/* {this.state.PageNameList.map((data) => {
                      return (
                        <option key={data.label} value={data.label}>
                          {data.label}
                        </option>
                      );
                    })} */}
                        </select>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
        <Card>
          <CardTitle
            style={{
              padding: "20px",
              marginBottom: "14px",
              border: "none",
            }}
            className="gtrendz-header"

          >
            <h4 style={{color:"#fff"}}>Your Enquiries</h4>  
          </CardTitle>
          <CardBody>
            <Table hover>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Personal Info. </th>
                  <th>Description</th>
                  <th>Type</th>{" "}
                </tr>
              </thead>
              <tbody>
                {Array(1, 2, 3, 4, 5).map((data) => (
                  <tr>
                    <th scope="row">{data}</th>
                    <td>
                      <span>Mark</span>
                      <br />
                      <span>mark@gmail.com</span>
                      <br />
                      <span>9999999999</span>
                    </td>
                    <td>
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit.{" "}
                      <br />
                      Itaque, vitae ab eaque accusantium earum sit quod corrupti
                      quam quas vero <br /> deserunt rem eveniet ullam, atque
                      aliquam nemo expedita?
                    </td>
                    <td>type</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default EnquiryDataGrid;
