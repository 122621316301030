import React, { useEffect, useRef, useState } from 'react'
import   "./PhotoUploader.css";
import EmptyBox from '../assets/images/users/EmptyBox.png'
import UploadPlaceHolder from '../assets/images/users/UploadPlaceHolder.png'

const PhotoUploader=(props)=> {
    const [file, setFile] = useState(null)
    const [previweURL, setpreviweURL] = useState(EmptyBox)
    const [imageURL, setimageURL] = useState('')
    const [isValid, setisValid] = useState(false)

 const filePickerRef=useRef()

 useEffect(() => {
    if(!file){
        return
    }
   const fileReader =new FileReader()
  fileReader.onload=() =>{ 
      setpreviweURL(fileReader.result)
  }


   fileReader.readAsDataURL(file)

 }, [file])
 

 const PhotoChangeHandler=(event) => {
    let pickedFile;
    let fileisValid=isValid
    if(event.target.files && event.target.files.length===1){
        pickedFile = event.target.files[0];
        console.log(pickedFile)
       setFile(pickedFile)
       setisValid(true)
       fileisValid=true
    }
    else{
        setisValid(false)
        fileisValid=false
     }
     props.onInput(props.id,pickedFile,fileisValid)
 }


    return (
        <div className=''>
           <div className="col-md-12">
               <div className="col-md-6">
                   
             <input id={props.id}
          style={{display:'none'}} 
          type='file'
          onChange={PhotoChangeHandler}
          accept=".jpg,.png,.jpeg"
          ref={filePickerRef}
          />
          
          <i onClick={()=>{
               setFile(null)
               setpreviweURL(EmptyBox)
               

           }} className=" fa fa-times-circle closeicon-class" aria-hidden="true" ></i>
          
              
           
          <div className={`image-upload ${props.center&&'center'}`}>
              <div onMouseLeave={()=>{
                  if(!file){
                    setpreviweURL(EmptyBox)
                  }
                  }}
                   onMouseEnter={()=>{
                    if(!file){
                        setpreviweURL(UploadPlaceHolder)
                    }
                    }}

                   onClick={()=>{filePickerRef.current.click()}} className="image-upload__preview ">
         

              <img  src={previweURL} alt='preview' height='auto' width='300px' />

              </div>
             
              </div> 
               </div>


           </div>


        </div>
    )
}
export default  PhotoUploader