import React, { Component } from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

import ApexRadial from "./ApexRadial";

import ReactGA from 'react-ga';

class GoogleAnalytics extends Component {
    setGA = () => {
        ReactGA.initialize('UA-97029983-1');
        ReactGA.pageview('test-init-pageview');
      };
    constructor(props) {
        super(props);
        this.state = {};
        
    }
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    render() {
        return (
            <div className="App container">
            <h2>Google Analytics Example</h2>
          </div>
        );
    }
}

export default GoogleAnalytics;
