import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import PhotoUploader from "../../components/PhotoUploader";
import GETApiCall from "../../GETAPI";
import Notiflix from "notiflix";
import POSTApiCall from "../../Api";

class AddDigital extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Date: '',
      Image: '',
      Content: '',
      URL: '',
    }
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: '#fa6e03'

    });
    // Notiflix.Loading.Standard();

  }
  onchangeDate(text) {
    this.setState({
      Date: text.target.value
    })
  }
  onchangeContent(text) {
    this.setState({
      Content: text.target.value
    })
  }
  onchangeURL(text) {
    this.setState({
      URL: text.target.value
    })
  }

  saveDigital() {
    if (this.state.Date !== '') {
      if (this.state.Content != '') {
        if (this.state.URL != '') {
          console.log('true');
        }
        else {
          Notiflix.Notify.Failure('Please enter URL');
        }
      }
      else {
        Notiflix.Notify.Failure('Please enter Content');
      }
    }
    else {
      Notiflix.Notify.Failure('Please enter Date');
    }
  }
  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Global Trendz "}
              breadcrumbItem={"Add Digital"}
            />

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="gtrendz-bg-soft-pink">
                    <Row>
                      <Col xs="9">
                        <div className="gtrendz-text  p-3">
                          <h5 className="gtrendz-text">
                            Add Digital Master
                          </h5>
                        </div>
                      </Col>
                    </Row>
                  </div>


                  <CardBody className="pt-0" style={{ height: "100%" }}>
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row">
                            <div className="col-md-12">

                              <div className="row">
                                <div className="col-md-4">
                                  <label>Post Date</label>
                                  <input
                                    className="form-control"
                                    type="date"
                                    value={this.state.Date}
                                    onChange={this.onchangeDate.bind(this)}></input>
                                </div>
                                <div className="col-md-4">
                                  <label>Post Content </label>
                                  <input className="form-control"
                                    value={this.state.Content}
                                    onChange={this.onchangeContent.bind(this)}></input>
                                </div>
                                <div className="col-md-4">
                                  <label>Post URL </label>
                                  <input className="form-control"
                                    value={this.state.URL}
                                    onChange={this.onchangeURL.bind(this)}></input>
                                </div>


                              </div>
                              <br></br>
                              <div className="row">
                                <div className="col-md-6" style={{ float: 'center' }}>
                                  <label>Post Image</label>
                                  <PhotoUploader value={this.state.Image} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>


                </Card>


                <Card>
                  <CardBody>





                    <div>
                      <Button
                        style={{ float: "right" }}
                        className=" btn gtrendz-btn "
                        onClick={this.saveDigital.bind(this)}
                      >
                        Submit{" "}

                      </Button>
                    </div>


                  </CardBody>
                </Card>


              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddDigital;
