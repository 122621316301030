
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";

export default class ViewVisitor extends Component {

    constructor(props) {
        super(props)
        this.state = {
            UserId: this.props.match.params.id,
            Visitor: {}
        }
    }


    componentDidMount() {

        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("Please wait...");


        PostApiCall.postRequest(
            {
                WhereClause: `where FLD_USER_ID=${this.state.UserId}`,
            },
            "Get_Visitor"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)
                    this.setState({ Visitor: obj.data[0], });
                    Notiflix.Loading.Remove();
                }
            })
        )

    }


    render() {
        return (
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs breadcrumbItem={"Vistor Management"} urlPath={"/visitor-list"} />

                    <Row>
                        <Col xl="12">
                            <Card className="pagebackground">
                                <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                    <div className="row my-1">
                                        <div className="col-2">
                                            <div className="form-group">
                                                <h4 className="FilterCard" style={{ marginTop: '10px' }}>View Vistor Details</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>


                    <div className='row  m-0'>

                        <h3 className='col-12 mt-3'>Personal Details</h3>

                        <div className='col-1'>
                            <img src={this.state.Visitor.FLD_PHOTO} width="100%" />
                        </div>

                        <div className='col-md-8 ms-5 row m-0'>
                            {/* <p className='col-md-6'><b>Full Name</b> : {`${this.state.Visitor.FLD_TITLE} ${this.state.Visitor.FLD_FIRST_NAME} ${this.state.FLD_MIDDLE_NAME != null && this.state.FLD_MIDDLE_NAME} ${this.state.Visitor.FLD_LAST_NAME}`} </p> */}
                            <p className='col-md-6'><b>Full Name</b> : {`${this.state.Visitor.FLD_FIRST_NAME}`} </p>
                            <p className='col-md-6'><b>Designation</b> : {this.state.Visitor.FLD_DESIGNATION}</p>
                            <p className='col-md-6'><b>Comapany Name</b> : {this.state.Visitor.FLD_COMPANY}</p>
                            <p className='col-md-6'><b>Email</b> : {this.state.Visitor.FLD_EMAIL_ID}</p>
                            {/* <p className='col-md-6'><b>Email</b> : {this.state.Visitor.FLD_EMAIL_ID_1}</p> */}
                            {/* <p className='col-md-6'><b>Mobile Number</b> : {this.state.Visitor.FLD_TEL_NUMBER}</p> */}
                            <p className='col-md-6'><b>Mobile Number</b> : {this.state.Visitor.FLD_MOBILE1}</p>

                            <p className='col-md-6'><b>Invited By</b> : {this.state.Visitor.FLD_INVITED_BY}</p>
                            <p className='col-md-6'><b>Invited Name</b> : {this.state.Visitor.FLD_INVITED_BY_NAME}</p>

                            <p className='col-md-6'><b>COVID Dose Status</b> : {this.state.Visitor.FLD_COVID_VACCINE}</p>
                        </div>

                        <h3 className='col-12 mt-3'>Address Details</h3>

                        <div className='row m-0'>
                            {/* <p className='col-md-4'><b>Address </b> :{`${this.state.Visitor.FLD_ADDRESS_LINE1} ${this.state.FLD_ADDRESS_LINE2 != null && this.state.FLD_ADDRESS_LINE2} ${this.state.FLD_ADDRESS_LINE3 != null && this.state.FLD_ADDRESS_LINE3}`}</p> */}

                            <p className='col-md-4'><b>Address </b> :</p>
                            <p className='col-md-4'><b>Pincode</b> : {this.state.Visitor.FLD_PINCODE}</p>
                            <p className='col-md-4'><b>City</b> : {this.state.Visitor.FLD_CITY}</p>
                            <p className='col-md-4'><b>State</b> : {this.state.Visitor.FLD_STATE}</p>
                            <p className='col-md-4'><b>Country</b> : India </p>
                            <p className='col-md-4'><b>Tele Number</b> : {this.state.Visitor.FLD_TEL_NUMBER != null && this.state.Visitor.FLD_TEL_NUMBER}</p>
                            <p className='col-md-4'><b>Whatsapp Number</b> : {this.state.Visitor.FLD_WHATSAPP_NO != null && this.state.Visitor.FLD_WHATSAPP_NO}</p>
                            <p className='col-md-4'><b>Email ID 2</b> : {this.state.Visitor.FLD_EMAIL_2 != null && this.state.Visitor.FLD_EMAIL_2}</p>
                            <p className='col-md-4'><b>Website</b> : {this.state.Visitor.FLD_WEBSITE != null && this.state.Visitor.FLD_WEBSITE}</p>
                        </div>

                        <h3 className='col-12 mt-3'>Type of business</h3>
                        <ol>
                            {this.state.Visitor?.FLD_TYPE_OF_BUSINESS?.split(",").map(val => <li>{val}</li>)}
                        </ol>

                        <h3 className='col-12 mt-3'>Products of your Interests</h3>
                        <ol>
                            {this.state.Visitor?.FLD_PRODUCT_INTERESTED_IN?.split(",").map(val => <li>{val}</li>)}
                        </ol>

                        <h3 className='col-12 mt-3'>Purpose of your Visits</h3>
                        <ol>
                            {this.state.Visitor?.FLD_PURPOSE_VISIT?.split(",").map(val => <li>{val}</li>)}
                        </ol>

                    </div>













                </Container>
            </div>

        )
    }
}
