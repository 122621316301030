
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";

export default class ViewVisitor extends Component {

    constructor(props) {
        super(props)
        this.state = {
            UserId: this.props.match.params.id,
            Visitor: {},
            Exhibitor: {}
        }
    }


    componentDidMount() {

        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("Please wait...");


        PostApiCall.postRequest(
            {
                WhereClause: `where FLD_EXH_ID=${this.state.UserId}`,
            },
            "Get_Exhibitor"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)
                    this.setState({ Visitor: obj.data[0] });
                    Notiflix.Loading.Remove();
                }
            })
        )

    }


    render() {
        return (
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={('Dashboard')} breadcrumbItem={"View Exhibitor"} />

                    <Row>
                        <Col xl="12">
                            <Card className="pagebackground">
                                <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                    <div className="row my-1">
                                        <div className="col-5">
                                            <div className="form-group">
                                                <h4 className="FilterCard" style={{ marginTop: '10px' }}>View Exhibitor Details</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>


                    <div className='row m-0'>

                        {/* <h3 className='col-12 mt-3'>Personal Details</h3> */}

                        {/* <div className='col-4'>
                            <img src={this.state.Visitor.FLD_PHOTO} width="100%" />
                        </div> */}


                        {/* <div className='col-md-12 row m-0'>
                            <p className='col-md-6'><b>Full Name</b> : {`${this.state.Visitor.FLD_TITLE} ${this.state.Visitor.FLD_FIRST_NAME} ${this.state.FLD_MIDDLE_NAME != null && this.state.FLD_MIDDLE_NAME} ${this.state.Visitor.FLD_LAST_NAME}`} </p>
                            <p className='col-md-6'><b>Designation</b> : {this.state.Visitor.FLD_DESIGNATION}</p>
                            <p className='col-md-6'><b>Comapany Name</b> : {this.state.Visitor.FLD_COMPANY}</p>
                            <p className='col-md-6'><b>Email</b> : {this.state.Visitor.FLD_EMAIL_ID_1}</p>
                            <p className='col-md-6'><b>Mobile Number</b> : {this.state.Visitor.FLD_TEL_NUMBER}</p>

                            <p className='col-md-6'><b>Invited By</b> : {this.state.Visitor.FLD_INVITED_BY}</p>
                            <p className='col-md-6'><b>Invited Name</b> : {this.state.Visitor.FLD_INVITED_BY_NAME}</p>

                            <p className='col-md-6'><b>COVID Dose Status</b> : {this.state.Visitor.FLD_COVID_VACCINE}</p>
                        </div> */}


                        {/* <h3 className='col-12 mt-3'>Address Details</h3> */}

                        <div className='row m-0'>
                            {/* <p className='col-md-4'><b>Address </b> :{`${this.state.Visitor.FLD_ADDRESS_LINE1} ${this.state.FLD_ADDRESS_LINE2 != null && this.state.FLD_ADDRESS_LINE2} ${this.state.FLD_ADDRESS_LINE3 != null && this.state.FLD_ADDRESS_LINE3} `}</p> */}
                            <p className='col-md-4'><b>Organistion type</b> : {this.state.Visitor.FLD_CLIENT_FROM}</p>
                            <p className='col-md-4'><b>Participation type</b> : {this.state.Visitor.FLD_PARTICIPATION}</p>

                            {this.state.Visitor.FLD_PARTICIPATION == "Through" && <p className='col-md-4'><b>Agent Name</b> : {this.state.Visitor.FLD_AGENT_NAME}</p>}

                            {this.state.Visitor.FLD_PARTICIPATION == "Through" && <p className='col-md-4'><b>Agent Email Id</b> : {this.state.Visitor.FLD_AGENT_EMAIL}</p>}

                            <p className='col-md-4'><b>Company Name</b> : {this.state.Visitor.FLD_COMPANY_NAME}</p>
                            <p className='col-md-4'><b>Booth Area</b> : {this.state.Visitor.FLD_APPROX_AREA} sq. mtrs. </p>
                            <p className='col-md-4'><b>Booth Type</b> : {this.state.Visitor.FLD_TEL_NUMBER != null && this.state.Visitor.FLD_TEL_NUMBER}</p>
                            <p className='col-md-4'><b>Whatsapp Number</b> : {this.state.Visitor.FLD_WHATSAPP_NO != null && this.state.Visitor.FLD_WHATSAPP_NO}</p>
                            <p className='col-md-4'><b>Email ID 2</b> : {this.state.Visitor.FLD_EMAIL_2 != null && this.state.Visitor.FLD_EMAIL_2}</p>
                            <p className='col-md-4'><b>Website</b> : {this.state.Visitor.FLD_WEBSITE != null && this.state.Visitor.FLD_WEBSITE}</p>
                        </div>


                        <h3 className='col-12 mt-3'>Brands</h3>

                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Sr.</th>
                                    <th scope="col">Brand Name</th>
                                    <th scope="col">Products</th>
                                    <th scope="col">Country Of Origin</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.Visitor?.FLD_BRANDS?.split("#").map((item, i) => {
                                    console.log(item.split(","))
                                    return (
                                        <tr>
                                            <th scope="row">{i + 1}</th>
                                            <td>{item.split(",")[0] == undefined ? " " : item.split(",")[0]}</td>
                                            <td>{item.split(",")[1] == undefined ? " " : item.split(",")[1]}</td>
                                            <td>{item.split(",")[2] == undefined ? " " : item.split(",")[2]}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>


                        <h3 className='col-12 mt-3'>Organisation Head Details</h3>

                        {/* <div className='row m-0'>
                            <p className='col-md-4'><b>Full Name</b> : {`${this.state.Visitor.FLD_ORG_HEAD_TITLE} ${this.state.Visitor.FLD_ORG_HEAD_FIRST_NAME} ${this.state.Visitor.FLD_ORG_HEAD_LAST_NAME}`} </p>

                            <p className='col-md-4'><b>Designation </b> : {this.state.Visitor.FLD_DESIGNATION}</p>

                            <p className='col-md-4'><b>Mobile Number </b> : {this.state.Visitor.FLD_MOBILE}</p>

                            <p className='col-md-4'><b>Email ID </b> : {this.state.Visitor.FLD_EMAIL_ID}</p>
                        </div> */}


                        <div className='row m-0'>

                            <p className='col-md-4'><b>Full Name</b> : {`${this.state.Visitor.FLD_CONTACT_PERSON_TITLE} ${this.state.Visitor.FLD_CONTACT_PERSON_FIRST_NAME} ${this.state.Visitor.FLD_CONTACT_PERSON_LASTNAME}`} </p>

                            <p className='col-md-4'><b>Designation </b> : {this.state.Visitor.FLD_CONTACT_PERSON_DESIGNATION}</p>

                            <p className='col-md-4'><b>Mobile Number </b> : {this.state.Visitor.FLD_CONTACT_PERSON_MOB_NUMBER}</p>

                            <p className='col-md-4'><b>Whatsapp Number </b> : {this.state.Visitor.FLD_CONTACT_PERSON_WHATSAPP != null && this.state.Visitor.FLD_CONTACT_PERSON_WHATSAPP}</p>

                            <p className='col-md-4'><b>Email ID </b> : {this.state.Visitor.FLD_CONTACT_PERSON_EMAIL_ID}</p>

                            <p className='col-md-4'><b>Address </b> : {`${this.state.Visitor.FLD_CONTACT_PERSON_ADDRESS_1} ${this.state.FLD_CONTACT_PERSON_ADDRESS_2 != null && this.state.FLD_CONTACT_PERSON_ADDRESS_2} ${this.state.FLD_CONTACT_PERSON_ADDRESS_3 != null && this.state.FLD_CONTACT_PERSON_ADDRESS_3}`} </p>

                        </div>


                        <h3 className='col-12 mt-3'>Contact Person Details</h3>

                        <div className='row m-0'>

                            <p className='col-md-4'><b>Full Name</b> : {`${this.state.Visitor.FLD_CONTACT_PERSON_TITLE} ${this.state.Visitor.FLD_CONTACT_PERSON_FIRST_NAME} ${this.state.Visitor.FLD_CONTACT_PERSON_LASTNAME}`} </p>

                            <p className='col-md-4'><b>Designation </b> : {this.state.Visitor.FLD_CONTACT_PERSON_DESIGNATION}</p>

                            <p className='col-md-4'><b>Mobile Number </b> : {this.state.Visitor.FLD_CONTACT_PERSON_MOB_NUMBER}</p>

                            <p className='col-md-4'><b>Whatsapp Number </b> : {this.state.Visitor.FLD_CONTACT_PERSON_WHATSAPP != null && this.state.Visitor.FLD_CONTACT_PERSON_WHATSAPP}</p>

                            <p className='col-md-4'><b>Email ID </b> : {this.state.Visitor.FLD_CONTACT_PERSON_EMAIL_ID}</p>

                            <p className='col-md-4'><b>Address </b> : {`${this.state.Visitor.FLD_CONTACT_PERSON_ADDRESS_1} ${this.state.FLD_CONTACT_PERSON_ADDRESS_2 != null && this.state.FLD_CONTACT_PERSON_ADDRESS_2} ${this.state.FLD_CONTACT_PERSON_ADDRESS_3 != null && this.state.FLD_CONTACT_PERSON_ADDRESS_3}`} </p>

                            <p className='col-md-4'><b>Pincode</b> : {this.state.Visitor.FLD_CONTACT_PERSON_PINCODE}</p>
                            <p className='col-md-4'><b>City</b> : {this.state.Visitor.FLD_CONTACT_PERSON_CITY}</p>
                            <p className='col-md-4'><b>State</b> : {this.state.Visitor.FLD_CONTACT_PERSON_STATE}</p>
                            <p className='col-md-4'><b>Country</b> : {this.state.Visitor.FLD_CONTACT_PERSON_COUNTRY} </p>
                            <p className='col-md-4'><b>Tel Number</b> : {this.state.Visitor.FLD_CONTACT_PERSON_TEL_NO}</p>
                            <p className='col-md-4'><b>Fax Number</b> : {this.state.Visitor.FLD_CONTACT_PERSON_FAX_NO}</p>

                            <p className='col-md-4'><b>Website</b> : {this.state.Visitor.FLD_CONTACT_PERSON_WEBSITE != null && this.state.Visitor.FLD_CONTACT_PERSON_WEBSITE}</p>

                        </div>


                        <ol>
                            {this.state.Visitor?.FLD_PRODUCT_INTERESTED_IN?.split(",").map(val => <li>{val}</li>)}
                        </ol>

                        <h3 className='col-12 mt-3'>Purpose of your Visits</h3>
                        <ol>
                            {this.state.Visitor?.FLD_PURPOSE_VISIT?.split(",").map(val => <li>{val}</li>)}
                        </ol>


                    </div>
                </Container>
            </div>

        )
    }
}
