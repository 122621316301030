import React, { Component } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.png";
// import logox from "../../assets/images/logox.png";
import logox from "../../assets/images/favicon-14.png";


class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: {
        value: '123456',
        errormassege: '',
        error: false
      },
      oldPassword: {
        value: '123456',
        errormassege: '',
        error: false
      },
      confirmPassword: {
        value: '123456',
        errormassege: '',
        error: false
      }
    };
  }


  onOldPasswordChange = (e) => {

    this.setState({
      ...this.state,
      oldPassword: {
        ...this.oldPassword,
        value: e.target.value
      }
    })
  }


  onOldPasswordBlur = (e) => {
    if (e.target.value.length === 0) {

      this.setState({
        ...this.state,
        oldPassword: {
          ...this.oldPassword,
          errormassege: "Old Password Feild could not be empty",
          error: true,
        }
      })

    }



  }


  onNewPasswordChange = (e) => {

    this.setState({
      ...this.state,
      newPassword: {
        ...this.newPassword,
        value: e.target.value
      }
    })
  }


  onNewPasswordBlur = (e) => {
    if (e.target.value.length === 0) {

      this.setState({
        ...this.state,
        newPassword: {
          ...this.newPassword,
          errormassege: "New Password Feild could not be empty",
          error: true,
        }
      })

    }



  }
  onconfirmPasswordChange = (e) => {

    this.setState({
      ...this.state,
      confirmPassword: {
        ...this.confirmPassword,
        value: e.target.value
      }
    })
  }


  onconfirmPasswordBlur = (e) => {
    if (e.target.value.length === 0) {

      this.setState({
        ...this.state,
        confirmPassword: {
          ...this.confirmPassword,
          errormassege: "confirm Password Feild could not be empty",
          error: true,
        }
      })

    }



  }



  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="gtrendz-bg-soft-pink">
                    <Row>
                      <Col className="col-7">
                        <div className="gtrendz-muted-text p-4">
                          <h5 className="gtrendz-muted-text">Welcome Back !</h5>
                          <p>Change Password to continue to Global Trendz Dashbaord.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="#">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logox} alt="" className="rounded-circle" height="44" />

                          </span>

                        </div>
                      </Link>
                    </div>



                    <div className="p-2">

                      <AvForm
                        className="form-horizontal mt-4"
                      >


                        <div className="form-group">
                          <label for="pwd">Old Password:</label>
                          <input
                            value={this.state.oldPassword.value} type="password" onChange={(e) => {
                              this.onOldPasswordChange(e)
                            }}
                            onBlur={(e) => {
                              this.onOldPasswordBlur(e)
                            }}
                            className="form-control" placeholder="Enter password" id="pwd" />
                          {this.state.oldPassword.error && <div className="is-invalid text-danger">{this.state.oldPassword.errormassege}</div>}

                        </div>
                        <div className="form-group">
                          <label for="pwd">New Password:</label>
                          <input
                            value={this.state.newPassword.value} type="password" onChange={(e) => {
                              this.onNewPasswordChange(e)
                            }}
                            onBlur={(e) => {
                              this.onNewPasswordBlur(e)
                            }}
                            className="form-control" placeholder="Enter password" id="pwd" />
                          {this.state.newPassword.error && <div className="is-invalid text-danger">{this.state.newPassword.errormassege}</div>}

                        </div>

                        <div className="form-group">
                          <label for="pwd">Confirm Password:</label>
                          <input
                            value={this.state.confirmPassword.value} type="password" onChange={(e) => {
                              this.onconfirmPasswordChange(e)
                            }}
                            onBlur={(e) => {
                              this.onconfirmPasswordBlur(e)
                            }}
                            className="form-control" placeholder="Enter password" id="pwd" />
                          {this.state.confirmPassword.error && <div className="is-invalid text-danger">{this.state.confirmPassword.errormassege}</div>}

                        </div>

                        <Row className="form-group">
                          <Col className="text-right">
                            <button
                              className="btn gtrendz-btn w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link
                      to="pages-login"
                      className="font-weight-medium gtrendz-text"
                    >
                      Login
                    </Link>{" "}
                  </p>
                  <p>© {new Date().getFullYear()} Global Trendz Dashbaord. Powered  <i className="mdi mdi-heart text-danger"></i> by Global Trendz</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default ChangePasswordPage;
