import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from "notiflix";

class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Website: "",
      Facebook_Page: "",
      Instagram_Page: "",
      Google_Page: "",
      Person_Name: "",
      Person_Number: "",
      Number: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      NumRegex: /^[0-9]*$/,
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#fa6e03",
    });

    // Notiflix.Loading.Standard();
  }

  onchangeName(text) {
    this.setState({
      Name: text.target.value,
    });
  }

  onchangeWebsite(text) {
    this.setState({
      Website: text.target.value,
    });
  }

  onchangeFacebook(text) {
    this.setState({
      Facebook_Page: text.target.value,
    });
  }

  onchangeInstagram(text) {
    this.setState({
      Instagram_Page: text.target.value,
    });
  }

  onchangeBusiness(text) {
    this.setState({
      Google_Page: text.target.value,
    });
  }

  onchangePerson_Name(text) {
    this.setState({
      Person_Name: text.target.value,
    });
  }

  onchangeNumber(text) {
    if (
      this.state.NumRegex.test(text.target.value) &&
      text.target.value.length <= 10
    ) {
      this.setState({
        Person_Number: text.target.value,
      });
    }
  }

  SaveName() {
    if (this.state.Name !== "") {
      if (this.state.Website != "") {
        if (this.state.Facebook_Page != "") {
          if (this.state.Instagram_Page != "") {
            if (this.state.Google_Page != "") {
              if (this.state.Person_Name != "") {
                if (this.state.Person_Number != "") {
                  if (this.state.Person_Number.length == 10) {
                    console.log("true");
                  } else {
                    Notiflix.Notify.Failure("Please enter valid number");
                  }
                } else {
                  Notiflix.Notify.Failure("Please enter contact person number");
                }
              } else {
                Notiflix.Notify.Failure("Please enter contact person name");
              }
            } else {
              Notiflix.Notify.Failure("Please enter Google business page link");
            }
          } else {
            Notiflix.Notify.Failure("Please enter instagram link");
          }
        } else {
          Notiflix.Notify.Failure("Please enter facebook link");
        }
      } else {
        Notiflix.Notify.Failure("Please enter website link");
      }
    } else {
      Notiflix.Notify.Failure("Please enter Name");
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Global Trendz "}
              breadcrumbItem={"Add Client"}
            />

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="gtrendz-bg-soft-pink">
                    <Row>
                      <Col xs="9">
                        <div className="gtrendz-text  p-3">
                          <h5 className="gtrendz-text">Add Client Master</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <CardBody className="pt-0" style={{ height: "100%" }}>
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="col-md-12">
                                <label>Name</label>
                                <input
                                  className="form-control"
                                  value={this.state.Name}
                                  onChange={this.onchangeName.bind(this)}
                                ></input>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-md-4">
                                  <label>Website</label>
                                  <input
                                    className="form-control"
                                    value={this.state.Website}
                                    onChange={this.onchangeWebsite.bind(this)}
                                  ></input>
                                </div>
                                <div className="col-md-4">
                                  <label>Facebook Page</label>
                                  <input
                                    className="form-control"
                                    value={this.state.Facebook_Page}
                                    onChange={this.onchangeFacebook.bind(this)}
                                  ></input>
                                </div>
                                <div className="col-md-4">
                                  <label>Instagram Page</label>
                                  <input
                                    className="form-control"
                                    value={this.state.Instagram_Page}
                                    onChange={this.onchangeInstagram.bind(this)}
                                  ></input>
                                </div>
                              </div>
                              <br></br>
                              <div className="row">
                                <div className="col-md-4">
                                  <label>Google Business Page</label>
                                  <input
                                    className="form-control"
                                    value={this.state.Google_Page}
                                    onChange={this.onchangeBusiness.bind(this)}
                                  ></input>
                                </div>
                                <div className="col-md-4">
                                  <label>Contact Person Name </label>
                                  <input
                                    className="form-control"
                                    value={this.state.Person_Name}
                                    onChange={this.onchangePerson_Name.bind(
                                      this
                                    )}
                                  ></input>
                                </div>
                                <div className="col-md-4">
                                  <label>Contact Person Mobile Number</label>
                                  <input
                                    className="form-control"
                                    value={this.state.Person_Number}
                                    onChange={this.onchangeNumber.bind(this)}
                                  ></input>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div>
                      <Button
                        style={{ float: "right" }}
                        className=" btn gtrendz-btn "
                        onClick={this.SaveName.bind(this)}
                      >
                        Submit{" "}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddClient;
