import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
class BarGraphVisualization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      GraphData:null,
      series: [
        {
          data: [21, 22, 10, 28, 16, 21, 13, 30],
        },
      ],
      options: {
        chart: {
          events: {
            click: function (chart, w, e) {
              console.log(chart, w, e)
            },
          },
        },
        // xs: 0,
        // sm: 576px,
        // md: 768px,
        // lg: 992px,
        // xl: 1200px,
        // xxl: 1400px

        colors: ["#556ee6", "#f1b44c", "#34c38f"],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [
            ["John", "Doe"],
            ["Joe", "Smith"],
            ["Jake", "Williams"],
            "Amber",
            ["Peter", "Brown"],
            ["Mary", "Evans"],
            ["David", "Wilson"],
            ["Lily", "Roberts"],
          ],
          labels: {
            style: {
              colors: ["#556ee6", "#f1b44c", "#34c38f"],
              fontSize: "12px",
            },
          },
          responsive: [
            {
              breakpoint: 1000,
              options: {
                  chart:{
                      width:200,
                  },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  }
                },
                legend: {
                  position: "bottom"
                }
              }
            }
          ]
        },
      },
    };
  }

  componentDidMount() {
    var loginDetails = JSON.parse(localStorage.getItem("LoginDetail"));
        // Notiflix.Loading.Dots("");
        console.log(loginDetails);
        console.log(loginDetails[0].fld_userid)
        // PostApiCall.postRequest(
        //   {
        //     clientId: loginDetails[0].fld_userid,
        //   },
        //   "getEnquiryData"
        // ).then((resultdes) =>
        //   resultdes.json().then((obj) => {
        //     this.setState(
        //       {
        //         GraphData: obj.data,
        //       },
        //       () => {
        //         console.log(this.state.GraphData,"this is graph api")
        //         Notiflix.Loading.Remove();
        //       }
        //     );
        //   })
        // );
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          width={960}
          height={285}
          type="bar"
        />
      </div>
    );
    }
}
export default BarGraphVisualization;
