import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";

import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png'
import Modal from 'react-responsive-modal';
import Notiflix from "notiflix";
import PostApiCall from "../../Api";

import { Link } from "react-router-dom";

class UserMaster extends Component {
  state = {
    UserData: [],

    Userid: null,
    Name: "",
    Email: "",
    Password: "",
    Department: "",
    isPasswordVisible: false,
    ShowModal: false,
    DepartmentList: [],
    EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  };


  componentDidMount() {


    Notiflix.Loading.Init({
      svgColor: "#fa6e03",
    });
    this.getFloorMaster()
    this.fetchUser()

  }


  fetchUser() {

    PostApiCall.postRequest(
      {
        WhereClause: '',
      },
      "getuser"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data)
          this.setState({ UserData: obj.data })
          Notiflix.Loading.Remove();
        }
      })
    )
  }


  getFloorMaster() {

    PostApiCall.postRequest(
      {
        WhereClause: '',
      },
      "getfloormaster"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj.data)
          this.setState({ DepartmentList: obj.data })
          Notiflix.Loading.Remove();
        }
      })
    )
  }



  addUser() {
    if (this.state.Name != "") {
      if (this.state.Email != "") {
        if (this.state.Password != "") {
          if (this.state.Department != "") {

            Notiflix.Loading.Dots("Please wait...");

            PostApiCall.postRequest(
              {
                userid : this.state.Userid,
                name: this.state.Name,
                email: this.state.Email,
                password: this.state.Password,
                department: this.state.Department
              },
              "Add_User"
            ).then((results) =>
              // const objs = JSON.parse(result._bodyText)
              results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  Notiflix.Loading.Remove();
                  this.setState({ ShowModal: false, Name: "", Email: "", Password: "", Department: "" }, () => this.fetchUser())
                  Notiflix.Notify.Success("User is successfully added.");
                } else {
                  Notiflix.Notify.Failure(obj.data);
                }
              })
            );

          } else {
            Notiflix.Notify.Failure("Please select department");
          }
        } else {
          Notiflix.Notify.Failure("Please enter password ");
        }
      } else {
        Notiflix.Notify.Failure("Please enter User ID");
      }
    } else {
      Notiflix.Notify.Failure("Please enter your name");
    }
  }


  render() {

    // console.log(this.state.Department)

    const data = {
      columns: [
        {
          label: "S.No.",
          field: "sn",
          // sort: "asc",
          width: 150,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 270,
        },
        {
          label: "User ID",
          field: "email",
          sort: "disabled",
          width: 200,
        },
        {
          label: "Department",
          field: "department",
          sort: "disabled",
          width: 100,
        },
        {
          label: "Status",
          field: "status",
          sort: "disabled",
          width: 100,
        },

        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],
      rows: this.state.UserData.map((data, i) => {
        
        return {
          sn: (i + 1),
          name: data.fld_name,
          email: data.fld_email,
          department: this.state.DepartmentList.find(val => val.fld_department_id == data.fld_department).fld_department_name,
          status: data.fld_status,
          action: <span className="btn btn-secondary" onClick={() => this.setState({ userid: data.fld_userid, Name: data.fld_name,  Email: data.fld_email, Password: "", Department: data.fld_department, ShowModal : true  }) } >Edit User</span>

        }

      })
    }


    // console.log(this.state.inputList)
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={"Kalanjiam Hardware "}
              breadcrumbItem={"User Master"}
            />

            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row className="justify-content-between">
                      <Col xs="3">
                        <div className="Bechofy-text  p-3">
                          <h5 className="Bechofy-text">
                            User Master List
                          </h5>
                        </div>
                      </Col>

                      <Col xs="auto">
                        <a
                          onClick={() => this.setState({ ShowModal: true })}
                          style={{
                            marginTop: "10px",
                            background: 'white',
                            color: 'grey',
                            marginRight: '20px'
                          }}
                          className="btn align-items-center btn"
                        >
                          <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img></span>
                          Add User
                        </a>
                      </Col>
                    </Row>
                  </div>
                </Card>



                {this.state.UserData.length != 0 ?
                  <MDBDataTable
                    hover
                    // scrollY
                    striped
                    bordered
                    data={data}
                    seachTop={false}
                    entriesOptions={[10, 20, 40, 60, 80, 100, 120, 140]}
                  >

                    <MDBTableHead columns={data.columns} />
                    <MDBTableBody rows={data.rows} />
                  </MDBDataTable>
                  :
                  <p>No Data Found</p>}




              </Col>
            </Row>
          </Container>


          <Modal class="modal-content" open={this.state.ShowModal} center>


            <div class=" col-12 m-0 mb-3 d-flex align-items-center justify-content-between Bechofy-bg-soft-pink">
              <h4 class="text-white py-2 ps-3" >Add User</h4>
              <i style={{ color: "white", fontSize: "30px", cursor: "pointer" }} onClick={() => {
                this.setState({ ShowModal: false })
              }} class="bi bi-x-circle"></i>
            </div>


            <div className="row">
              <div className="col-md-6 mb-3">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.Name}
                  onChange={e => this.setState({ Name: e.target.value })}
                ></input>
              </div>



              <div className="col-md-6 mb-3">
                <label>User ID</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.Email}
                  onChange={e => this.setState({ Email: e.target.value })}
                ></input>
              </div>

              <div className="col-md-6 mb-3">
                <label>Password</label>
                <input
                  className="form-control"
                  type={this.state.isPasswordVisible ? "text" : "password"}
                  value={this.state.Password}
                  onChange={e => this.setState({ Password: e.target.value })}
                ></input>
                <span>
                  <i
                    className="fa fa-eye"
                    onClick={() => { this.setState({ isPasswordVisible: !this.state.isPasswordVisible, }); }}
                    style={{ float: "right", bottom: "12px", position: "absolute", right: "20px", }}
                  ></i>
                </span>
              </div>


              <div class="col-md-6 mb-3">
                <label for="inputState">Department</label>
                <select id="inputState" class="form-control" onChange={e => this.setState({ Department: e.target.value })}>
                  <option value="" >Choose...</option>
                  {this.state.DepartmentList.map(val => <option value={val.fld_department_id} selected={val.fld_department_id == this.state.Department} >{val.fld_department_name}</option>)}
                </select>
              </div>


            </div>

            <div className="row m-0 justify-content-end">


              <button class="btn col-2 btn-secondary" onClick={this.addUser.bind(this)} >Save</button>

              <button class="btn col-2 btn-secondary ml-3" onClick={() => this.setState({ ShowModal: false })} >Cancel</button>

            </div>

          </Modal>

        </div>
      </React.Fragment>
    );
  }
}
export default UserMaster;