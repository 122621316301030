import React from "react";
import { Redirect } from "react-router-dom";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Inner Authentication
import LockScreen from "../pages/Authentication/auth-lock-screen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Crypto

import ChangePasswordPage from '../pages/Authentication/ChangePassword';

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//mailer
import Mailer from "../pages/Mailer/MailerForm";
import MailerBooking from "../pages/Mailer/MailerFormBooking";

// ================02/04/2021========================

import UserMaster from "../pages/UserManagement/UserMaster";
import AddUser from "../pages/UserManagement/AddUser";
import ClientMaster from "../pages/UserManagement/ClientMaster";
import AddClient from "../pages/UserManagement/AddClient";
import AddEmploye from '../pages/UserManagement/AddEmployee';
import EmployeMAster from '../pages/UserManagement/EmployeeMaster'
import DigitalMaster from "../pages/UserManagement/DigitalMaster";
import AddDigital from "../pages/UserManagement/AddDigital";


//=====================14-04-2021 Deepanshi================

// import ItemMaster from "../pages/SalesManagement/ItemMaster";
import VisitorList from "../pages/VisitorMangement/VisitorList";
import ViewVisitor from "../pages/VisitorMangement/ViewVisitor.js";

import ExhibitorList from "../pages/ExhibitorMangement/ExhibitorList";
import ExhibitorManagement from "../pages/ExhibitorMangement/ExhibitorManagement.js"
import ViewExhibitor from "../pages/ExhibitorMangement/ViewExhibitor";

import Enquiries from "../pages/Enquiries/Enquiries";

import CustomerList from "../pages/CustomerManagement/CustomerList";



const authProtectedRoutes = [

  { path: "/dashboard", component: Dashboard },

  //Crypto

  //profile
  { path: "/profile", component: UserProfile },

  //calendar
  { path: "/calendar", component: Calendar },

  //Ecommerce

  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Tables

  //  User Management
  // =================02/04/2021=======================
  { path: "/userMaster", component: UserMaster },
  { path: "/addUser", component: AddUser },
  { path: "/clientMaster", component: ClientMaster },
  { path: "/addClient", component: AddClient },
  { path: "/addEmployee", component: AddEmploye },
  { path: "/employeemaster", component: EmployeMAster },
  { path: "/digitalMaster", component: DigitalMaster },
  { path: "/addDigital", component: AddDigital },

  //================14-04-2021=====================
  // { path: "/itemMaster", component: ItemMaster },

  { path: "/view-visitor/:id", component: ViewVisitor },
  { path: "/visitor-list", component: VisitorList },

  { path: "/view-exhibitor/:id", component: ViewExhibitor },
  { path: "/exhibitor-list", component: ExhibitorList },
  { path: "/exhibitor-management", component: ExhibitorManagement },


  { path: "/userMaster", component: UserMaster },
  { path: "/enquiries", component: Enquiries },

  { path: "/customer-list", component: CustomerList },


  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/change-password-screen", component: ChangePasswordPage },
  { path: "/register", component: Register },


  // Authentication Inner
  { path: "/pages-login", component: Login },//Login1
  { path: "/pages-register", component: Register },//Register1
  { path: "/pages-forgot-pwd", component: ForgetPwd },//Forgetpwd1
  { path: "/auth-lock-screen", component: LockScreen },//LockScreen

  // Authentication Inner
  { path: "/mailer/:clientid/:mailtype", component: Mailer },//Login1
  // Authentication Inner
  { path: "/bookingmailer/:clientid/:mailtype", component: MailerBooking },//Login1

];

export { authProtectedRoutes, publicRoutes };
